import React from "react";
import ServerPagination from "../../../components/ui/ServerPagination";

export const AdminSubscribedUsersList = ({
  data,
  totalCount,
  currentPage,
  pagination,
}) => {
  return (
    <>
      <table className="table">
        <thead>
          <tr>
           <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Plan Status</th>
            <th>Variation</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>DeActivate Date</th>
          </tr>
        </thead>
        {data?.length > 0 ? (
          <tbody>
            {data?.map((item) => (

                <tr key={`plan-${item.id}`}>
                    <td>{item.user.name}</td>
                    <td>{item.user.email}</td>
                    <td>{item.user.phone_number}</td>
                    <td>{item.is_active ? `Active` : `Inactive`}</td>
                    <td>{item?.variation?.duration_type} Month</td>
                    <td>{item.start_date}</td>
                    <td>{item.end_date}</td>
                    <td>{item.deActive_date ?? `-`}</td>
                </tr>
            ))}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan={9}>No Data Found</td>
            </tr>
          </tbody>
        )}
      </table>
      {data.length > 0 && (
        <ServerPagination
          allData={data}
          getPage={(page) => pagination(page)}
          totalCount={totalCount}
          setPage={currentPage}
        />
      )}
    </>
  );
};
