import React, {useState} from "react";
import useInput from "../../../hooks/use-input";
import Calendar from "react-calendar";
import OutsideClickHandler from "react-outside-click-handler";
import {getDateToStringWithoutTime, getDateWithoutTime} from "../../../lib/api";
import {getFormatDate} from "../../../lib/admin-api";

const AdminTransactionSearch = ({submitSearch, resetSearch}) => {
    const [selectedVariation, setSelectedVariationStatus] = useState("");
    const [selectedTransactionStatus, setSelectedTransactionStatus] = useState("");
    const {
        value: userName,
        valueChangeHandler: userNameChangeHandler,
        inputBlurHandler: userNameBlurHandler,
        resetValue: resetUserName,
    } = useInput((value) => value.trim().length > 0 && value.trim().length < 99);

    const {
        value: planName,
        valueChangeHandler: planNameChangeHandler,
        inputBlurHandler: planNameBlurHandler,
        resetValue: resetPlanName,
    } = useInput((value) => value.trim().length > 0 && value.trim().length < 99);

    const {
        value: amount,
        valueChangeHandler: amountChangeHandler,
        inputBlurHandler: amountBlurHandler,
        resetValue: resetamount,
    } = useInput((value) => value.trim().length > 0 && value.trim().length < 99);

    const {
        value: session,
        valueChangeHandler: sessionChangeHandler,
        inputBlurHandler: sessionBlurHandler,
        resetValue: resetsession,
    } = useInput((value) => value.trim().length > 0 && value.trim().length < 99);

    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [isDateFilterActive, setIsDateFilterActive] = useState(false);
    const [date, setDate] = useState([new Date()]);

    const formIsInvalid = false;

    const formSubmitHandler = (event) => {
        event.preventDefault();

        let start_date = "";
        let end_date = "";
        if (isDateFilterActive) {
            start_date = date[0] ? getFormatDate(date[0]) : "";
            end_date = date[1] ? getFormatDate(date[1]) : "";
        } else {
            start_date = "";
            end_date = "";
        }
        if (!formIsInvalid) {
            submitSearch({
                userName,
                planName,
                selectedVariation,
                amount,
                session,
                selectedTransactionStatus,
                start_date,
                end_date
            });
        }

        submitSearch({
            userName,
            planName,
            selectedVariation,
            amount,
            session,
            selectedTransactionStatus,
            start_date,
            end_date
        });
    };

    const toggleCalendar = () => {
        setIsCalendarOpen(!isCalendarOpen);
    };

    const dateChangeHandler = (selectedDate) => {
        setDate(selectedDate);
        setIsCalendarOpen(false);
    };

    const displayDate = () => {
        if (date[1]) {
            if (
                getDateWithoutTime(date[0]).getTime() ===
                getDateWithoutTime(date[1]).getTime()
            ) {
                setDate([date[0]]);
                return getDateToStringWithoutTime(date[0]);
            } else {
                return (
                    getDateToStringWithoutTime(date[0]) +
                    " - " +
                    getDateToStringWithoutTime(date[1])
                );
            }
        }
        return getDateToStringWithoutTime(date[0]);
    };

    return (
        <div className="admin-search-wrapper">
            <h2 className="search-title">Search</h2>
            <form onSubmit={formSubmitHandler}>
                <ul className="list-style-none flex flex-wrap items-start">
                    <li>
                        <label>User</label>
                        <input type="text"
                               className="number-list"
                               placeholder="User Name"
                               value={userName}
                               onChange={userNameChangeHandler}
                               onBlur={userNameBlurHandler}
                        />
                    </li>
                    <li>
                        <label>Plan</label>
                        <input type="text"
                               className="number-list"
                               placeholder="Plan Name"
                               value={planName}
                               onChange={planNameChangeHandler}
                               onBlur={planNameBlurHandler}
                        />
                    </li>
                    <li>
                        <label>Variation</label>
                        <select onChange={(event) => {
                            setSelectedVariationStatus(event.target.value);
                        }}
                                className="number-list"
                                value={selectedVariation}
                        >
                            <option value="">All</option>
                            <option value="1">1 Month</option>
                            <option value="12">12 Month</option>
                            <option value="24">24 Month</option>
                            <option value="36">36 Month</option>
                        </select>
                    </li>
                    <li>
                        <label>Amount</label>
                        <input type="text"
                               className="number-list"
                               placeholder="Amount"
                               value={amount}
                               onChange={amountChangeHandler}
                               onBlur={amountBlurHandler}
                        />
                    </li>
                    <li>
                        <label>Session</label>
                        <input type="text"
                               className="number-list"
                               placeholder="session"
                               value={session}
                               onChange={sessionChangeHandler}
                               onBlur={sessionBlurHandler}
                        />
                    </li>
                    <li>
                        <label>Status</label>
                        <select onChange={(event) => {
                            setSelectedTransactionStatus(event.target.value);
                        }}
                                className="number-list"
                                value={selectedTransactionStatus}
                        >
                            <option value="">All</option>
                            <option value="pending">Pending</option>
                            <option value="active">Active</option>
                            <option value="canceled">Canceled</option>
                        </select>
                    </li>
                    <OutsideClickHandler onOutsideClick={() => {
                        setIsCalendarOpen(false);
                    }}>
                        <li>
                            <input
                                type="checkbox"
                                checked={isDateFilterActive}
                                onChange={() => {
                                    setIsDateFilterActive(!isDateFilterActive);
                                }}
                            />
                            Date Filter
                            <button
                                type="button"
                                className={`number-list ${
                                    !isDateFilterActive && "disabled-link"
                                } ${isCalendarOpen ? "expanded" : ""}`}
                                onClick={toggleCalendar}
                            >
                                <strong>Date / Date Range: </strong>
                                <span>{displayDate()}</span>
                            </button>
                            <div className={`calender-wrap ${isCalendarOpen ? "show" : ""}`}>
                                <Calendar
                                    onChange={dateChangeHandler}
                                    closeCalendar={true}
                                    selectRange={true}
                                />
                            </div>
                        </li>
                    </OutsideClickHandler>
                    <li>
                        <label style={{opacity: '0'}}>Search</label>
                        <button type="submit" className='signup-btn submit-btn'>
                            Search
                        </button>
                    </li>
                    <li>
                        <label style={{opacity: '0'}}>Reset</label>
                        <button onClick={(event) => {
                            event.preventDefault();
                            resetSearch();
                            resetUserName();
                            resetPlanName();
                            resetamount();
                            resetsession();
                            setDate([new Date()]);
                            setSelectedTransactionStatus("");
                            setSelectedVariationStatus("")
                        }}
                                className="signup-btn submit-btn"
                        >
                            Reset
                        </button>
                    </li>
                </ul>
            </form>
        </div>
    );
};

export default AdminTransactionSearch;
