import React, { useEffect, useRef, useState } from "react";
import "./singleTripDetails.scss";
import { TripDetailsCover } from "./trip-details-cover/TripDetailsCover";
import { TripGearList } from "./trip-gear/TripGearList";
import { useParams, useNavigate } from "react-router-dom";
import {
  getSingleTripDetails,
  updateTripDescription,
  acceptTripInvite,
  declineOrLeaveInvite,
} from "../../lib/api";
import useHttp from "../../hooks/use-http";
import { SpinnerModal } from "../../components/ui/SpinnerModal";
import useInput from "../../hooks/use-input";
import ApiErrors from "../../components/api-errors/ApiErrors";
import ModalWithInfo from "../../components/ui/ModalWithInfo";
import ModalWithState from "../../components/ui/ModalWithState";
import { TripUsers } from "./trip-details-sidebar/TripUsers";
import {usePermissions} from "../../layout/Permissions";
import {toast} from "react-toastify";

export const SingleTripDetails = () => {
  const checkPermission = usePermissions();
  const params = useParams();
  const descriptionRef = useRef();
  const { sendRequest, status, error, data } = useHttp(getSingleTripDetails);

  const [refreshComponent, setRefreshComponent] = useState(false);
  const [openWelcomeModal, setOpenWelcomeModal] = useState(true);
  const [showTripEditBox, setShowTripEditBox] = useState(false);

  const refreshHandler = () => {
    setRefreshComponent(!refreshComponent);
  };

  const welcomeModalHandler = () => {
    setOpenWelcomeModal(!openWelcomeModal);
  };

  useEffect(() => {
    sendRequest(params.id);
  }, [sendRequest, refreshComponent, params.id]);

  const editTripDetails = async () => {
    const hasPermission = await checkPermission('edit_trip_details');
    if (!hasPermission) {
      return false;
    }
    descriptionRef.current = data?.trip?.trip?.description;
    setShowTripEditBox(true);
  }

  return (
    <>
      {!error ? (
        <>
          {status === "pending" || status === null ? (
            <SpinnerModal />
          ) : (
            <>
              {data.trip ? (
                <>
                  <div className="main-profile-detail flex flex-wrap column-direction w-100">
                    <TripDetailsCover
                      tripData={data.trip.trip}
                      attendingUsers={data.trip.attending_user}
                      refreshHandler={refreshHandler}
                    />
                    <div className="container">
                      {data.trip.trip.status === "canceled" && (
                        <p className="no-trip">
                          This trip is canceled. Please activate it to make any
                          edits.
                        </p>
                      )}
                      {data.trip.trip.status === "completed" && (
                        <p className="no-trip">
                          This trip is completed. Please re-activate it to make
                          any edits.
                        </p>
                      )}
                      {data.trip.trip.status === "active" && (
                        <div className="panel-wrap flex">
                          <div className="sidebar-wrap">
                            {data.trip.trip.trip_access === "invited" && (
                              <TripInviteBox
                                inviteSenderName={
                                  data.trip.trip.invite_sender_name
                                }
                                inviteId={data.trip.trip.invited_id}
                                inviteToken={data.trip.trip.token}
                                refreshHandler={refreshHandler}
                              />
                            )}
                            <div className="sidebar-wrapper flex flex-wrap">
                              <h2 className="side-title">Trip Description</h2>
                              {!showTripEditBox && (
                                <>
                                  {data.trip.trip.description !== null ? (
                                    <p>{data.trip.trip.description}</p>
                                  ) : (
                                    <p>
                                      No description is added for this trip.
                                    </p>
                                  )}
                                  {/* Show Edit button only to trip owner. */}
                                  {data.trip.trip.trip_access === "owner" && (
                                    <button
                                      className="signup-btn editDescription"
                                      onClick={editTripDetails}
                                    >
                                      <i className="fa fa-edit"></i> Edit
                                      Description
                                    </button>
                                  )}
                                </>
                              )}
                              {showTripEditBox && (
                                <EditTripDescriptionBox
                                  tripId={params.id}
                                  setShowTripEditBox={setShowTripEditBox}
                                  currentDescription={
                                    data.trip.trip.description
                                  }
                                  refreshHandler={refreshHandler}
                                />
                              )}
                            </div>
                            <TripUsers
                              attendingUsers={data.trip.attending_user}
                              //In case the user is viewing an invited trip, he will not receive these users
                              invitedUsers={
                                data.trip.invitees ? data.trip.invitees : null
                              }
                            />
                            {error && <ApiErrors>{error}</ApiErrors>}
                          </div>
                          <div className="gear-wrapper">
                            <TripGearList
                              tripId={params.id}
                              tripAccess={data.trip.trip.trip_access}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className={`confirmation-modal welcome-modal modal-wrap ${
                      openWelcomeModal && data.trip.trip.trip_access === "owner"
                        ? "active"
                        : ""
                    }`}
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-text">
                          <h3>
                            Welcome to Inspire Outdoors! (Beta).{" "}
                            <span>Our tool makes adventure planning easy.</span>
                          </h3>
                          <p>
                            Since you’ve already invited your buddies, let’s add
                            the gear you’ll take for your{" "}
                            <b>{data.trip.trip.activity}</b> adventure.
                          </p>
                          <button
                            className="signup-btn letsGO"
                            onClick={welcomeModalHandler}
                          >
                            Let’s Go!
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <p className="no-trip">Sorry! This trip could not be found.</p>
              )}
            </>
          )}
        </>
      ) : (
        <div className="main-profile-detail flex flex-wrap column-direction w-100" style={{ textAlign: 'center' }}>
          <ApiErrors>{error}</ApiErrors>
        </div>
      )}
    </>
  );
};

//Sub Component for Edit Description Form
const EditTripDescriptionBox = (props) => {
  const {
    sendRequest: sendUpdateDescriptionRequest,
    status: updateDescriptionStatus,
    error: updateDescriptionError,
  } = useHttp(updateTripDescription);

  const {
    value: enteredDescription,
    hasError: descriptionHasError,
    valueIsValid: descriptionIsValid,
    valueChangeHandler: descriptionChangeHandler,
    inputBlurHandler: descriptionBlurHandler,
  } = useInput(
    (value) => value.trim().length > 9 && value.trim().length < 501,
    props.currentDescription ? props.currentDescription : ""
  );

  const editDescriptionHandler = (event) => {
    event.preventDefault();
    if (descriptionIsValid) {
      const requestData = {
        tripId: props.tripId,
        description: { description: enteredDescription },
      };
      sendUpdateDescriptionRequest(requestData);
    }
  };

  useEffect(() => {
    if (updateDescriptionStatus === "completed" && updateDescriptionError === null) {
      props.setShowTripEditBox(false);
      props.refreshHandler();
    }

    if (updateDescriptionError) {
      const errorData = JSON.parse(updateDescriptionError);
      if (errorData.message === 'feature_error') {
        toast.error(errorData.errors['feature'][0] ?? '');
      }
    }
  }, [updateDescriptionStatus, updateDescriptionError, props]);

  return (
    <form className="search-filed">
      <textarea
        placeholder="Enter Trip Description..."
        value={enteredDescription}
        onChange={descriptionChangeHandler}
        onBlur={descriptionBlurHandler}
        cols="40"
        rows="10"
      ></textarea>
      {descriptionHasError && (
        <p className="error-msg">
          Please enter a valid description (10-500 characters)
        </p>
      )}
      <div className="flex">
        <button
          className={`side-btn ${descriptionIsValid ? "" : "disabled-link"}`}
          onClick={editDescriptionHandler}
        >
          Update Description
        </button>
        <button
          className="side-btn"
          onClick={() => {
            props.setShowTripEditBox(false);
          }}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

// Helper Component for accepting, declining a trip invite or leaving a tirp.

const TripInviteBox = (props) => {
  const inviteToken = props.inviteToken;
  const inviteId = props.inviteId;

  // console.log(inviteId);
  // console.log(inviteToken);

  const navigate = useNavigate();
  const [actionType, setActionType] = useState("");
  const [showActionModal, setShowActionModal] = useState(false);
  const [actionConfirmation, setActionConfirmation] = useState(false);
  const [showActionInfoModal, setShowActionInfoModal] = useState(false);

  const {
    sendRequest: sendAcceptTripInviteRequest,
    status: acceptTripInviteStatus,
    error: acceptTripInviteError,
  } = useHttp(acceptTripInvite);

  const {
    sendRequest: sendDeclineOrLeaveRequest,
    status: declineOrLeaveRequestStatus,
    error: declineOrLeaveRequestError,
  } = useHttp(declineOrLeaveInvite);

  useEffect(() => {
    //Because effect runs for the first time also
    if (actionConfirmation) {
      if (actionType === "accept") {
        const token = {
          token: inviteToken,
        };
        sendAcceptTripInviteRequest(token);
      } else if (actionType === "decline" || actionType === "leave") {
        sendDeclineOrLeaveRequest(inviteId);
      }
    }
    setActionConfirmation(false);
  }, [actionConfirmation]);

  useEffect(() => {
    if (
      acceptTripInviteStatus === "completed" &&
      acceptTripInviteError === null
    ) {
      setShowActionInfoModal(true);
    }
    if (
      declineOrLeaveRequestStatus === "completed" &&
      declineOrLeaveRequestError === null
    ) {
      setShowActionInfoModal(true);
    }
  }, [
    acceptTripInviteStatus,
    acceptTripInviteError,
    declineOrLeaveRequestStatus,
    declineOrLeaveRequestError,
  ]);

  //Hide the info modal and re-render the listing.
  const infoModalConfirmationHandler = () => {
    setShowActionInfoModal(false);
    if (actionType === "leave" || actionType === "decline") {
      navigate("/my-account", { replace: true });
    }
    props.refreshHandler();
  };

  return (
    <div className="sidebar-wrapper flex flex-wrap">
      <div className="sidebar-invite">
        <h2 className="side-title">
          {props.inviteSenderName} has invited you to this trip!
        </h2>
        <div className="flex">
          <button
            className="side-btn"
            onClick={() => {
              setActionType("accept");
              setShowActionModal(true);
            }}
          >
            Accept Invite
          </button>
          <button
            className="side-btn"
            onClick={() => {
              setActionType("decline");
              setShowActionModal(true);
            }}
          >
            Decline Invite
          </button>
        </div>
        {showActionModal && (
          <ModalWithState
            showModal={setShowActionModal}
            onConfirmModal={setActionConfirmation}
            message={
              (actionType === "accept" &&
                "Are you sure you would like to accept this trip invite?") ||
              (actionType === "decline" &&
                "Are you sure you would like to decline this trip invite?") ||
              (actionType === "leave" &&
                "Are you sure you would like to leave this trip?")
            }
          />
        )}
        {showActionInfoModal && (
          <ModalWithInfo
            showModal={infoModalConfirmationHandler}
            heading={"Success"}
            message={
              (actionType === "accept" &&
                "You have accepted this trip invite successfully.") ||
              (actionType === "decline" && "The trip invite is declined.") ||
              (actionType === "leave" &&
                "You are no longer an attendee on this trip.")
            }
          />
        )}
      </div>
    </div>
  );
};
