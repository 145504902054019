import React, {useEffect, useState} from "react";
import {communityTripsList} from "../../lib/api";
import {SpinnerModal} from "../../components/ui/SpinnerModal";
import useHttp from "../../hooks/use-http";
import List from "./List";
import Search from "./Search";
import ApiErrors from "../../components/api-errors/ApiErrors";
import {usePermissions} from "../../layout/Permissions";
import {useNavigate} from "react-router-dom";

export const CommunityTrips = () => {

    const checkPermission = usePermissions();
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(1);  // Store the current page
    const [searchParams, setSearchParams] = useState({
        location: "",
        activity: "",
        status: "",
        start_date: "",
        end_date: "",
    });

    const {sendRequest, status, error, data} = useHttp(communityTripsList);

    useEffect(() => {

        const permissions = async () => {
            const hasPermission = await checkPermission('community_trips');
            if (hasPermission) {
                let query = `?page=${currentPage}`;
                if (searchParams.location) {
                    query += `&location=${encodeURIComponent(searchParams.location)}`;
                }
                if (searchParams.activity) {
                    query += `&activity=${encodeURIComponent(searchParams.activity)}`;
                }
                if (searchParams.status) {
                    query += `&status=${searchParams.status}`;
                }
                if (searchParams.start_date) {
                    query += `&start_date=${searchParams.start_date}`;
                }
                if (searchParams.end_date) {
                    query += `&end_date=${searchParams.end_date}`;
                }
                sendRequest(query);
            } else {
                navigate('/trip-plan');
            }
        }
        permissions();
    }, [sendRequest, currentPage, searchParams]);

    const submitSearch = (data) => {
        setSearchParams({
            location: data.location,
            activity: data.activity,
            status: data.status,
            start_date: data.start_date,
            end_date: data.end_date,
        });
        setCurrentPage(1);
    }

    const resetSearch = () => {
        setSearchParams({
            location: '',
            activity: '',
            status: '',
            start_date: '',
            end_date: '',
        });
        setCurrentPage(1);  // Reset page to 1
    }

    const pagination = (page) => {
        setCurrentPage(page);  // Set the current page in state
    }

    return (
        <>
            {!error ? (
                <div className="main-profile-list flex flex-wrap w-100 content-justify-center">
                    <div className="container">
                        <h1>Community Trips</h1>
                        <Search submitSearch={(data) => submitSearch(data)} resetSearch={resetSearch}/>
                        {status === "pending" || status === null ? (
                            <SpinnerModal/>
                        ) : (
                            <>
                                <h3>List</h3>
                                <List data={data?.data}
                                         pagination={(page) => pagination(page)}
                                         totalCount={data?.total}
                                         currentPage={currentPage}  // Pass the current page
                                />
                            </>
                        )}
                    </div>
                </div>
            ) : (
                <div className="main-profile-detail flex flex-wrap column-direction w-100"
                     style={{textAlign: 'center'}}>
                    <ApiErrors>{error}</ApiErrors>
                </div>
            )}
        </>
    );
};
