import "./admin-all-users.scss";
import React, {useEffect, useState} from "react";
import {SpinnerModal} from "../../../components/ui/SpinnerModal";
import useHttp from "../../../hooks/use-http";
import {getAllUsers} from "../../../lib/admin-api";
import {AdminUsersList} from "./AdminUsersList";
import AdminUsersSearch from "./AdminUsersSearch";
import AdminApiErrors from "../../../components/api-errors/AdminApiErrors";

export const AdminAllUsers = () => {
    const {sendRequest, status, error, data} = useHttp(getAllUsers);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchData, setSearchData] = useState(null);

    const pagination = (page) => {
        setCurrentPage(page);  // Set the current page in state
    }

    useEffect(() => {
        let query = `?page=${currentPage}`;
        if (searchData) {
            query += `&name=${searchData.name}&email=${searchData.email}&phone_number=${searchData.phone}`;
        }
        sendRequest(query);  // Maintain search and pagination when refreshing data
    }, [currentPage, searchData]);

    // Reset search and current page
    const resetSearch = () => {
        sendRequest(``);  // Reset search params
        setCurrentPage(1);
        setSearchData(null);
        // Reset page to 1
    }

    // Reset search and current page
    const submitSearch = (data) => {
        setSearchData(data);
        setCurrentPage(1);
    }

    return (
        <>
            <div className="admin-panel-wrap admin-all-trips flex flex-wrap w-100 content-justify-center">
                <div className="container">
                    <h1>Users List</h1>
                    <AdminUsersSearch
                        resetSearch={resetSearch}
                        submitSearch={submitSearch}
                    />
                    {!error ? (
                        <div className="table-responsive">
                            {status === "pending" || status === null ? (
                                <SpinnerModal/>
                            ) : (
                                <AdminUsersList data={data?.data}
                                                pagination={(page) => pagination(page)}
                                                totalCount={data?.total}
                                                currentPage={currentPage}  // Pass the current page
                                />
                            )}
                        </div>
                    ) : (
                        <>
                            <AdminApiErrors>{error}</AdminApiErrors>
                            <p className="no-trip">
                                An error occured while fetching data from Backend. Please try
                                again later.
                            </p>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};
