import "./footer.scss";
import { Link } from "react-router-dom";
import React from 'react'

export const Footer = () => {
  return (
    <>
      <footer className="footer-wrapper flex flex-wrap">
        <div className="container flex items-center">
          <p>© Inspire Outdoors 2022</p>
          <section>
            <div>
              <Link to="/privacy-policy">Privacy Policy</Link>
              <Link to="/contact">Contact</Link>
              <Link to="/feedback">Provide Feedback</Link>
            </div>
            {/*<ul className="flex list-style-none footer-social items-center">*/}
            {/*  <li>*/}
            {/*    <a*/}
            {/*      href="https://www.facebook.com"*/}
            {/*      target="_blank"*/}
            {/*      rel="noreferrer noopener"*/}
            {/*      className="fa fa-facebook"*/}
            {/*    >*/}
            {/*      {" "}*/}
            {/*    </a>*/}
            {/*  </li>*/}
            {/*  <li>*/}
            {/*    <a*/}
            {/*      href="https://www.instagram.com"*/}
            {/*      target="_blank"*/}
            {/*      rel="noreferrer noopener"*/}
            {/*      className="fa fa-instagram"*/}
            {/*    >*/}
            {/*      {" "}*/}
            {/*    </a>*/}
            {/*  </li>*/}
            {/*</ul>*/}
          </section>
        </div>
      </footer>
    </>
  );
};
