import React, {useState} from "react";
import useInput from "../../../hooks/use-input";

const AdminSubscriptionPlansSearch = ({submitSearch, resetSearch}) => {
    const [selectedStatus, setSelectedStatus] = useState("");
    const [selectedDefault, setSelectedDefault] = useState("");
    const [selectedPublish, setSelectedPublish] = useState("");
    const {
        value: planName,
        valueChangeHandler: planNameChangeHandler,
        inputBlurHandler: planNameBlurHandler,
        resetValue: resetPlanName,
    } = useInput((value) => value.trim().length > 0 && value.trim().length < 99);

    const formSubmitHandler = (event) => {
        event.preventDefault();
        submitSearch({planName, selectedStatus, selectedDefault, selectedPublish})
    };

    return (
        <div className="admin-search-wrapper">
            <h2 className="search-title">Search</h2>
            <form onSubmit={formSubmitHandler}>
                <ul className="list-style-none flex flex-wrap items-start">
                    <li>
                        <label>Name</label>
                        <input type="text"
                               className="number-list"
                               placeholder="Plan Name"
                               value={planName}
                               onChange={planNameChangeHandler}
                               onBlur={planNameBlurHandler}
                        />
                    </li>
                    <li>
                        <label>Status</label>
                        <select onChange={(event) => {
                            setSelectedStatus(event.target.value);
                        }}
                                className="number-list"
                                value={selectedStatus}
                        >
                            <option value="">All</option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                        </select>
                    </li>
                    <li>
                        <label>Default</label>
                        <select onChange={(event) => {
                            setSelectedDefault(event.target.value);
                        }}
                                className="number-list"
                                value={selectedDefault}
                        >
                            <option value="">All</option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>
                    </li>
                    <li>
                        <label>Publish</label>
                        <select onChange={(event) => {
                            setSelectedPublish(event.target.value);
                        }}
                                className="number-list"
                                value={selectedPublish}
                        >
                            <option value="">All</option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>
                    </li>
                    <li>
                    <label style={{opacity:'0'}}>Search</label>
                        <button type="submit" className='signup-btn submit-btn'>
                            Search
                        </button>
                    </li>

                    <li>
                    <label style={{opacity:'0'}}>Reset</label>
                        <button onClick={(event) => {
                            event.preventDefault();
                            resetSearch();
                            resetPlanName();
                            setSelectedStatus("");
                            setSelectedDefault("");
                            setSelectedPublish("");
                        }}
                                className="signup-btn submit-btn"
                        >
                            Reset
                        </button>
                    </li>
                </ul>
            </form>
        </div>
    );
};

export default AdminSubscriptionPlansSearch;
