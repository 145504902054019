import React, { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import checkIcon from "../../../assets/images/checked.png";
import info from "../../../assets/images/dots.png";
import { deletePersonalGearItem, deleteGroupGearItem } from "../../../lib/api";
import useHttp from "../../../hooks/use-http";
import ModalWithState from "../../../components/ui/ModalWithState";
import EditGearItem from "./EditGearItem";
import ApiErrors from "../../../components/api-errors/ApiErrors";
import {usePermissions, useFeature} from "../../../layout/Permissions";

const GearDropDownList = (props) => {
  const checkPermission = usePermissions();
  const isEditGearFeature = useFeature('edit_personal_gear_list');
  const isDeleteGearFeature = useFeature('delete_personal_gear_list');
  const isEditGroupGearFeature = useFeature('edit_group_gear_list');
  const isDeleteGroupGearFeature = useFeature('delete_group_gear_list');
  const gearItem = props.gearItem;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [editGearItem, setEditGearItem] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [actionConfirmation, setActionConfirmation] = useState(false);

  const { sendRequest, status, error } = useHttp(deletePersonalGearItem);
  const {
    sendRequest: sendDeleteGroupGearRequest,
    status: deleteGroupGearStatus,
    error: deleteGroupGearError,
  } = useHttp(deleteGroupGearItem);

  const dropdownHandler = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    if (actionConfirmation) {
      if (props.isMyGroupGear || props.isOtherGroupGear) {
        const gearData = {
          gearId: gearItem.id,
          data: {
            my_trip_id: gearItem.my_trip_id,
          },
        };
        sendDeleteGroupGearRequest(gearData);
      } else {
        sendRequest(gearItem.id);
      }
    }
  }, [actionConfirmation]);

  useEffect(() => {
    if (status === "completed" && error === null) {
      props.refreshHandler();
    }
    if (
      deleteGroupGearStatus === "completed" &&
      deleteGroupGearError === null
    ) {
      props.refreshHandler();
    }
  }, [status, error, deleteGroupGearStatus, deleteGroupGearError]);

  const editGear = async () => {
    if (props?.type === 'my') {
      const hasPermissionMy = await checkPermission('edit_personal_gear_list');
      if (!hasPermissionMy) {
        return false;
      }
    } else if (props?.type === 'group') {
      const hasPermissionGroup = await checkPermission('edit_personal_gear_list');
      if (!hasPermissionGroup) {
        return false;
      }
    }
    setEditGearItem(true);
  }

  const deleteGear = async () => {
    if (props?.type === 'my') {
      const hasPermissionMy = await checkPermission('delete_personal_gear_list');
      if (!hasPermissionMy) {
        return false;
      }
    } else if (props?.type === 'group') {
      const hasPermissionGroup = await checkPermission('delete_group_gear_list');
      if (!hasPermissionGroup) {
        return false;
      }
    }
    setShowDeleteModal(true);
  }

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setIsDropdownOpen(false);
      }}
    >
      <div className="info" onClick={dropdownHandler}>
        <img src={info} alt="info" />
        <div className={`info-detail ${isDropdownOpen ? "show" : ""}`}>
          {/* Only show edit option if it is user's own gear item */}
          {!props.isOtherGroupGear && ((props?.type === 'my' && isEditGearFeature) || (props?.type === 'group' && isEditGroupGearFeature)) && (
            <span onClick={editGear}><img src={checkIcon} alt="" />Edit Item</span>
          )}
          {((props?.type === 'my' && isDeleteGearFeature) || (props?.type === 'group' && isDeleteGroupGearFeature)) && (
              <span onClick={deleteGear}><img src={checkIcon} alt=""/>Delete Item</span>
          )}
        </div>
      </div>
      {editGearItem && (
          <EditGearItem
              showEditGearForm={setEditGearItem}
          gearItem={gearItem}
          refreshHandler={props.refreshHandler}
          isMyGroupGear={props.isMyGroupGear}
        />
      )}
      {showDeleteModal && (
        <ModalWithState
          message={"Are you sure you would like to delete this Gear Item?"}
          onConfirmModal={setActionConfirmation}
          showModal={setShowDeleteModal}
        />
      )}
      {error && <ApiErrors>{error}</ApiErrors>}
      {deleteGroupGearError && <ApiErrors>{deleteGroupGearError}</ApiErrors>}
    </OutsideClickHandler>
  );
};

export default GearDropDownList;
