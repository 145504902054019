import React, { useEffect, useState } from "react";
import "./ToggleSwitch.css";
import ServerPagination from "../../../components/ui/ServerPagination";
import VariationModal from "./VariationModal";
import ConfirmationModal from "./ConfirmationModal";
import { adminSubscriptionPlanPublishToggle } from "../../../lib/admin-api";
import useHttp from "../../../hooks/use-http";
import { useNavigate } from "react-router-dom";

export const AdminSubscriptionPlansList = ({
  data,
  actionToggleStatus,
  disableStatus,
  actionToggleDefault,
  disableDefault,
  totalCount,
  currentPage,
  pagination,
  refreshData,
}) => {
  const {
    sendRequest: publishSendRequest,
    status: publishStatus,
    error: publishError,
    data: publishData,
  } = useHttp(adminSubscriptionPlanPublishToggle);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(null);
  const navigate = useNavigate();

  const handleOpenVariationModal = (item) => {
    setModalData(item);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalData(null);
  };

  const handlePublish = (item) => {
    setCurrentPlan(item);
    setIsConfirmationModalOpen(true);
  };

  const confirmPublish = () => {
    if (currentPlan) {
      publishSendRequest(currentPlan.id);
    }
  };

  const handleRedirect = (id) => {
    navigate(`/admin-32423QeCFer/subscrubed-users-list/${id}`);
  };

  useEffect(() => {
    if (publishStatus === "completed" && publishError === null) {
      refreshData();
    }
  }, [publishStatus, publishError, publishData]);

  return (
    <>
      <table className="table">
        <thead>
        <tr>
          <th>Plan</th>
          <th>Features</th>
          <th>Description</th>
          <th>Publish</th>
          <th>Status</th>
          <th>Default</th>
          <th>Action</th>
        </tr>
        </thead>
        {data.length > 0 ? (
          <tbody>
            {data.map((item) => (
                <tr key={`plan-${item.id}`}>
                  <td>{item.name}</td>
                  <td>
                    <ul
                        style={{
                          textAlign: "left",
                          height: "90px",
                          overflowY: "scroll",
                        }}
                    >
                      {item.features.map((feature) => (
                          <li key={`feature-${feature.id}`}>{feature.name}</li>
                      ))}
                    </ul>
                  </td>
                  <td style={{ maxWidth: "150px"}}>
                    <div
                        style={{
                          textAlign: "left",
                          height: "90px",
                          overflowY: "scroll",
                        }}
                    >
                      {item.description}
                    </div>
                  </td>
                  <td>{item.is_publish === 1 ? "Yes" : "No"}</td>
                  <td>
                    <button
                        className={`btn btn-lg btn-toggle ${
                            item.is_active === 1 ? "active" : ""
                        }`}
                        onClick={() => actionToggleStatus(item)}
                        disabled={disableStatus === "pending"}
                    >
                      <div className="handle"></div>
                    </button>
                  </td>
                  <td>
                    <button
                        className={`btn btn-lg btn-toggle ${
                            item.is_default === 1 ? "active" : ""
                        }`}
                        onClick={() => actionToggleDefault(item)}
                        disabled={disableDefault === "pending"}
                    >
                      <div className="handle"></div>
                    </button>
                  </td>
                  <td>
                    <div>
                      <button
                          className="table-btn signup-btn submit-btn"
                          onClick={() => handleRedirect(item.id)} // Attach the handler
                      >
                        Subscribed Users
                      </button>
                    </div>
                    <div>
                      <button
                          className="table-btn signup-btn submit-btn"
                          onClick={() => handleOpenVariationModal(item)}
                      >
                        {item.is_publish === 0
                            ? "Add Variation"
                            : "See Variation"}
                      </button>
                    </div>

                    {item.is_publish === 0 && (
                        <div>
                          <button
                              className="table-btn signup-btn submit-btn"
                              onClick={() => handlePublish(item)}
                          >
                            Publish
                          </button>
                        </div>
                    )}
                  </td>
                </tr>
            ))}
          </tbody>
        ) : (
            <tbody>
            <tr>
              <td colSpan={9}>No Data Found</td>
            </tr>
            </tbody>
        )}
      </table>
      {data.length > 0 && (
          <ServerPagination
              allData={data}
              getPage={(page) => pagination(page)}
              totalCount={totalCount}
              setPage={currentPage}
          />
      )}
      {/* Render Modal */}
      <VariationModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        planData={modalData}
      />
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        onConfirm={confirmPublish}
        message="Are you sure you want to publish this plan?"
      />
    </>
  );
};
