const BASE_URL = process.env.REACT_APP_ADMIN_BASE_URL;

//This function creates ab object with same format for all errors thrown by the API
const formatErrors = (data) => {
  let errorData = {};
  errorData.message = data.message;
  if (data.errors) {
    errorData.errors = data.errors;
  } else if (data.error) {
    errorData.errors = { error: [data.error] };
  }
  return errorData;
};

//----->API Calls - Admin Side<-----
export async function loginAdmin(adminData) {
  const response = await fetch(`${BASE_URL}/login`, {
    method: "POST",
    body: JSON.stringify(adminData),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(
      JSON.stringify(formatErrors(data)) || "Could not admin login."
    );
  }
  return data.token;
}

export async function logoutAdmin() {
  const response = await fetch(`${BASE_URL}/logout`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("admin-token"),
    },
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(
      JSON.stringify(formatErrors(data)) || "Could not logout admin."
    );
  }
  return data;
}

export async function changeAdminPassword(requestData) {
  const response = await fetch(`${BASE_URL}/update/password`, {
    method: "PUT",
    body: JSON.stringify(requestData),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("admin-token"),
    },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(
      JSON.stringify(formatErrors(data)) || "Could not update admin password."
    );
  }
  return null;
}

export async function getAllUsers(parms) {
  const response = await fetch(`${BASE_URL}/all-users${parms}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("admin-token"),
    },
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(
      JSON.stringify(formatErrors(data)) || "Could not fetch all users."
    );
  }
  return data;
}

export async function getUserData(userId) {
  const response = await fetch(`${BASE_URL}/get-full-user-details/${userId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("admin-token"),
    },
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(
      JSON.stringify(formatErrors(data)) || "Could not fetch user data."
    );
  }
  return data;
}

export async function adminDeleteTrip(tripId) {
  const response = await fetch(`${BASE_URL}/delete-trip/${tripId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("admin-token"),
    },
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Could not delete trip.");
  }
  return data;
}

export async function adminGetAllTrips(params) {
  const query = params ?? '';
  const response = await fetch(`${BASE_URL}/all-trips${query}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("admin-token"),
    },
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(
      JSON.stringify(formatErrors(data)) || "Could not fetch all users."
    );
  }
  return data;
}

export async function adminGetSingleTripDetails(tripId) {
  const response = await fetch(`${BASE_URL}/one-trip-detail/${tripId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("admin-token"),
    },
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(
      JSON.stringify(formatErrors(data)) || "Could not fetch all users."
    );
  }
  return data;
}

export async function adminGetAllGearItems(tripId) {
  const response = await fetch(`${BASE_URL}/get-trip-gear/${tripId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("admin-token"),
    },
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(
      JSON.stringify(formatErrors(data)) ||
        "Could not fetch all gear items of a trip."
    );
  }
  return data;
}

export async function adminDeletePersonalGearItem(gearId) {
  const response = await fetch(`${BASE_URL}/delete-personal-gear/${gearId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("admin-token"),
    },
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Could not delete personal gear item.");
  }
  return data;
}

export async function adminDeleteGroupGearItem(gearId) {
  const response = await fetch(`${BASE_URL}/delete-group-gear/${gearId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("admin-token"),
    },
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Could not delete group gear item.");
  }
  return data;
}

export async function adminBlockUser(userId) {
  const response = await fetch(`${BASE_URL}/block-user/${userId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("admin-token"),
    },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(
      JSON.stringify(formatErrors(data)) || "Could not block the user."
    );
  }
  return null;
}

export async function adminUnblockUser(userId) {
  const response = await fetch(`${BASE_URL}/unblock-user/${userId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("admin-token"),
    },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(
      JSON.stringify(formatErrors(data)) || "Could not unblock the user."
    );
  }
  return null;
}

export async function getDashboardData() {
  const response = await fetch(`${BASE_URL}/dashboard`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("admin-token"),
    },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(
        JSON.stringify(formatErrors(data)) || "Could not fetch all users."
    );
  }
  return data;
}

export async function adminGetAllFeaturesList(params) {
  const query = params ?? '';
  const response = await fetch(`${BASE_URL}/features${query}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("admin-token"),
    },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(JSON.stringify(formatErrors(data)) || "Could not fetch all users.");
  }
  return data;
}

export async function adminFeatureToggle(id) {
  const response = await fetch(`${BASE_URL}/features/${id}/toggle`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("admin-token"),
    },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(JSON.stringify(formatErrors(data)) || "Could not fetch all users.");
  }
  return data;
}

export async function adminSubscriptionPlans(params) {
  const query = params ?? '';
  const response = await fetch(`${BASE_URL}/subscription-plans${query}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("admin-token"),
    },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(JSON.stringify(formatErrors(data)) || "Could not fetch all users.");
  }
  return data;
}

export async function adminSubscriptionPlanFeatures() {
  const response = await fetch(`${BASE_URL}/features/active`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("admin-token"),
    },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(JSON.stringify(formatErrors(data)) || "Could not fetch all users.");
  }
  return data;
}

export async function adminSubscriptionPlanCreate(requestData) {
  const response = await fetch(`${BASE_URL}/subscription-plans`, {
    method: "POST",
    body: JSON.stringify(requestData),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("admin-token"),
    },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(JSON.stringify(formatErrors(data)) || "Could not fetch all users.");
  }
  return data;
}

export async function adminSubscriptionPlanToggle(requestData) {
  const response = await fetch(`${BASE_URL}/subscription-plans/${requestData}/toggle`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("admin-token"),
    },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(JSON.stringify(formatErrors(data)) || "Not able to update status.");
  }
  return data;
}

export async function adminSubscriptionPlanDefaultToggle(requestData) {
  const response = await fetch(`${BASE_URL}/subscription-plans/${requestData}/toggle-default`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("admin-token"),
    },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(JSON.stringify(formatErrors(data)) || "Not able to make it default.");
  }
  return data;
}


export async function adminSubscriptionPlanVariationCreate(requestData) {
  const formattedRequest = {
    variations: [
      {
        duration_type: requestData.duration_type,
        price: requestData.price,
        discount: requestData.discount,
        offerPrice: requestData.offerPrice,
      }
    ]
  };
  const response = await fetch(`${BASE_URL}/subscription-plans/${requestData.planId}/variations`, {
    method: "POST",
    body: JSON.stringify(formattedRequest),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("admin-token"),
    },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(JSON.stringify(formatErrors(data)) || "Could not save variation.");
  }
  return data;
}

export async function adminFetchSubscriptionVariation(requestData) {
  const response = await fetch(`${BASE_URL}/subscription-plans/${requestData.planId}/variations`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("admin-token"),
    },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(JSON.stringify(formatErrors(data)) || "Could not fetch all variations.");
  }
  return data;
}

export async function adminDeleteSubscriptionVariation(requestData) {
  const response = await fetch(`${BASE_URL}/subscription-plans/${requestData.planId}/variations/${requestData.variationId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("admin-token"),
    },
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Could not delete Variation.");
  }
  return data;
}


export async function adminSubscriptionPlanPublishToggle(requestData) {
  const response = await fetch(`${BASE_URL}/subscription-plans/${requestData}/publishToggle`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("admin-token"),
    },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(JSON.stringify(formatErrors(data)) || "Not able to update status.");
  }
  return data;
}

// export async function AdminSubscribedUsersListFetch(requestData) {
//   const response = await fetch(`${BASE_URL}/subscription-plans/${requestData}/publishToggle`, {
//     method: "PATCH",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: "Bearer " + localStorage.getItem("admin-token"),
//     },
//   });
//   const data = await response.json();
//   if (!response.ok) {
//     throw new Error(JSON.stringify(formatErrors(data)) || "Not able to update status.");
//   }
//   return data;
// }


export async function AdminSubscribedUsersListFetch(params) {
  const query = params ?? '';
  const response = await fetch(`${BASE_URL}/users-with-subscriptions/${query}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("admin-token"),
    },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(JSON.stringify(formatErrors(data)) || "Could not fetch all users.");
  }
  return data;
}

export async function adminGetAllTransactionList(params) {
  const query = params ?? '';
  const response = await fetch(`${BASE_URL}/transactions${query}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("admin-token"),
    },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(JSON.stringify(formatErrors(data)) || "Could not fetch all transaction.");
  }
  return data;
}

export const getFormatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 since months are 0-based
  const day = String(date.getDate()).padStart(2, '0');        // Ensuring 2-digit format

  return `${year}-${month}-${day}`;
};
