// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy-policy-wrapper {
  padding: 50px 0;
}
.privacy-policy-wrapper h1 {
  font-size: 40px;
  margin: 0 0 20px;
}
.privacy-policy-wrapper p a {
  font-weight: bold;
  color: #fff;
  text-decoration: underline;
}
.privacy-policy-wrapper ol li {
  margin: 10px 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/informational-pages/privacyPolicy.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AAAE;EACE,eAAA;EACA,gBAAA;AAEJ;AACI;EACE,iBAAA;EACA,WAAA;EACA,0BAAA;AACN;AAGI;EAAK,cAAA;AAAT","sourcesContent":[".privacy-policy-wrapper {\n  padding: 50px 0;\n  h1 {\n    font-size: 40px;\n    margin: 0 0 20px;\n  }\n  p {\n    a {\n      font-weight: bold;\n      color: #fff;\n      text-decoration: underline;\n    }\n  }\n  ol {\n    li { margin: 10px 0}\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
