import axios from "axios";
import FileResizer from "react-image-file-resizer";

const BASE_URL = process.env.REACT_APP_BASE_URL;

//This function creates ab object with same format for all errors thrown by the API
const formatErrors = (data) => {
    let errorData = {};
    errorData.message = data.message;
    if (data.errors) {
        errorData.errors = data.errors;
    } else if (data.error) {
        errorData.errors = {error: [data.error]};
    }
    return errorData;
};

async function assignDefPlan(id) {
    await fetch(`${BASE_URL}/api/subscribe/default/${id}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
    });
}

// ***** Authentication *****
export async function registerUser(userData) {
    await axios.get(`${BASE_URL}/sanctum/csrf-cookie`);
    const response = await fetch(`${BASE_URL}/api/auth/register`, {
        method: "POST",
        body: JSON.stringify(userData),
        headers: {
            "Content-Type": "application/json",
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(
            JSON.stringify(formatErrors(data)) || "Could not create user."
        );
    }
    await assignDefPlan(data?.user_id);
    return null;
}

export async function verifyUser(verificationCode) {
    await axios.get(`${BASE_URL}/sanctum/csrf-cookie`);
    const response = await fetch(
        `${BASE_URL}/api/verify/email?code=${verificationCode}`,
        {
            method: "POST",
            body: JSON.stringify(verificationCode),
            headers: {
                "Content-Type": "application/json",
            },
        }
    );
    const data = await response.json();
    if (!response.ok) {
        throw new Error(JSON.stringify(formatErrors(data)) || "Code is invalid.");
    }
    return null;
}

export async function getNewCode(email) {
    await axios.get(`${BASE_URL}/sanctum/csrf-cookie`);
    const response = await fetch(
        `${BASE_URL}/api/new/code?email=${email}`,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        }
    );
    const data = await response.json();
    if (!response.ok) {
        throw new Error(JSON.stringify(formatErrors(data)) || "Code is invalid.");
    }
    return data;
}

export async function loginUser(userData) {
    const response = await fetch(`${BASE_URL}/api/auth/login`, {
        method: "POST",
        body: JSON.stringify(userData),
        headers: {
            "Content-Type": "application/json",
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(JSON.stringify(formatErrors(data)) || "Could not login.");
    }
    return data;
}

export async function logoutUser() {
    const response = await fetch(`${BASE_URL}/api/logout`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("user-token"),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(JSON.stringify(formatErrors(data)) || "Could not logout.");
    }
    return data;
}

export async function sendResetEmail(userData) {
    const response = await fetch(`${BASE_URL}/api/reset-password-token`, {
        method: "POST",
        body: JSON.stringify(userData),
        headers: {
            "Content-Type": "application/json",
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(
            JSON.stringify(formatErrors(data)) || "Could not send reset email."
        );
    }
    return data;
}

export async function resetPassword(userData) {
    const response = await fetch(`${BASE_URL}/api/password-reset`, {
        method: "POST",
        body: JSON.stringify(userData),
        headers: {
            "Content-Type": "application/json",
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(
            JSON.stringify(formatErrors(data)) || "Could not reset password."
        );
    }
    return data;
}

export async function getProfile(args) {
    const response = await axios.get('https://www.googleapis.com/oauth2/v1/userinfo', {
        headers: {
            Authorization: `Bearer ${args.access_token}`,
            Accept: 'application/json'
        }
    });
    if (response.status != '200') {
        throw new Error("Not able to get google profile.");
    }
    return response.data;
}

export async function socialLogin(userData) {
    const response = await fetch(`${BASE_URL}/api/auth/social/login`, {
        method: "POST",
        body: JSON.stringify(userData),
        headers: {
            "Content-Type": "application/json",
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(JSON.stringify(formatErrors(data)) || "Could not social login.");
    }
    if (data?.action === 'register') {
        await assignDefPlan(data?.user_id);
        return await socialLogin(userData);
    }
    return data;
}

export async function updateSocialLoginPhone(phone) {
    const response = await fetch(`${BASE_URL}/api/update-social-phone-number`, {
        method: "PUT",
        body: JSON.stringify(phone),
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("user-token"),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(
            JSON.stringify(formatErrors(data)) || "Could not update phone."
        );
    }
    return data;
}

// ***** Trips *****

export async function addNewTrip(tripData) {
    const response = await fetch(`${BASE_URL}/api/create-trip`, {
        method: "POST",
        body: JSON.stringify(tripData),
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("user-token"),
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(
            JSON.stringify(formatErrors(data)) || "Could not create trip."
        );
    }
    return null;
}

export async function getAllUserTrips() {
    const response = await fetch(`${BASE_URL}/api/my-trip?status=all`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("user-token"),
        },
    });
    const data = await response.json();
    if (!response.ok) {throw new Error(JSON.stringify(formatErrors(data)) || "Could not fetch user's trips.");}
    return data;
}

export async function getNearToExpiry(subscription_plan_id) {
    const response = await fetch(`${BASE_URL}/api/subscription-ending-soon?subscription_plan_id=${subscription_plan_id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("user-token"),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || "Could not fetch user's trips.");
    }
    return data;
}

export async function getSingleTripDetails(tripId) {
    const response = await fetch(`${BASE_URL}/api/sigle-trip-details/${tripId}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("user-token"),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        // throw new Error(data.message || "Could not fetch user's trips.");
        throw new Error(JSON.stringify(formatErrors(data)) || "Could not fetch user's trips.");
    }
    return data;
}

export async function updateTripDetails(tripData) {
    const response = await fetch(
        `${BASE_URL}/api/edit/trip/detail/${tripData.tripId}`,
        {
            method: "PATCH",
            body: JSON.stringify(tripData.data),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("user-token"),
            },
        }
    );
    const data = await response.json();
    if (!response.ok) {
        throw new Error(JSON.stringify(formatErrors(data)) || "Could not edit Trip Details.");
    }
    return null;
}

export async function changeTripStatus(requestData) {
    await axios.get(`${BASE_URL}/sanctum/csrf-cookie`);
    const response = await fetch(
        `${BASE_URL}/api/change/trip/status/${requestData.tripId}`,
        {
            method: "PUT",
            body: JSON.stringify(requestData.status),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("user-token"),
            },
        }
    );
    const data = await response.json();
    if (!response.ok) {
        throw new Error(
            JSON.stringify(formatErrors(data)) || "Could not change status."
        );
    }
    return null;
}

export async function changeTripPublicStatus(requestData) {
    await axios.get(`${BASE_URL}/sanctum/csrf-cookie`);
    const response = await fetch(
        `${BASE_URL}/api/change/trip/public/status/${requestData.tripId}`,
        {
            method: "PATCH",
            body: JSON.stringify(requestData.status),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("user-token"),
            },
        }
    );
    const data = await response.json();
    if (!response.ok) {
        throw new Error(
            JSON.stringify(formatErrors(data)) || "Could not change status."
        );
    }
    return null;
}

export async function updateTripDescription(requestData) {
    await axios.get(`${BASE_URL}/sanctum/csrf-cookie`);
    const response = await fetch(
        `${BASE_URL}/api/edit/trip/description/${requestData.tripId}`,
        {
            method: "PATCH",
            body: JSON.stringify(requestData.description),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("user-token"),
            },
        }
    );
    const data = await response.json();
    if (!response.ok) {
        throw new Error(JSON.stringify(formatErrors(data)) || "Could not update description.");
    }
    return null;
}

export async function deleteTrip(tripId) {
    const response = await fetch(`${BASE_URL}/api/delete-trip/${tripId}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("user-token"),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(JSON.stringify(formatErrors(data)) || "Could not delete trip.");
    }
    return data;
}

// ***** Gear Items *****

export async function getAllTripGear(tripId) {
    const response = await fetch(`${BASE_URL}/api/get-trip-gear/${tripId}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("user-token"),
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(
            JSON.stringify(formatErrors(data)) || "Could not fetch Gear Items."
        );
    }
    return data;
}

export async function addPersonalGearItem(itemData) {
    const response = await fetch(`${BASE_URL}/api/add-personal-gear`, {
        method: "POST",
        body: itemData,
        headers: {
            dataType: "JSON",
            Authorization: "Bearer " + localStorage.getItem("user-token"),
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(
            JSON.stringify(formatErrors(data)) || "Could not add Personal Gear Item."
        );
    }
    return null;
}

export async function addGroupGearItem(itemData) {
    const response = await fetch(`${BASE_URL}/api/add-gear-item`, {
        method: "POST",
        body: itemData,
        headers: {
            dataType: "JSON",
            Authorization: "Bearer " + localStorage.getItem("user-token"),
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(
            JSON.stringify(formatErrors(data)) || "Could not add Group Gear Item."
        );
    }
    return null;
}

export async function updatePersonalGearItem(gearData) {
    const response = await fetch(
        `${BASE_URL}/api/edit-personal-gear/${gearData.gearId}`,
        {
            method: "POST",
            body: gearData.data,
            headers: {
                dataType: "JSON",
                Authorization: "Bearer " + localStorage.getItem("user-token"),
            },
        }
    );
    const data = await response.json();
    if (!response.ok) {
        throw new Error(
            JSON.stringify(formatErrors(data)) || "Could not edit Personal Gear Item."
        );
    }
    return null;
}

export async function updateGroupGearItem(gearData) {
    const response = await fetch(
        `${BASE_URL}/api/edit-group-gear/${gearData.gearId}`,
        {
            method: "POST",
            body: gearData.data,
            headers: {
                dataType: "JSON",
                Authorization: "Bearer " + localStorage.getItem("user-token"),
            },
        }
    );
    const data = await response.json();
    if (!response.ok) {
        throw new Error(
            JSON.stringify(formatErrors(data)) || "Could not edit Group Gear Item."
        );
    }
    return null;
}

export async function deletePersonalGearItem(gearId) {
    const response = await fetch(
        `${BASE_URL}/api/delete-personal-gear/${gearId}`,
        {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("user-token"),
            },
        }
    );
    const data = await response.json();

    if (!response.ok) {
        throw new Error(JSON.stringify(formatErrors(data)) || "Could not delete personal gear item.");
    }
    return data;
}

export async function deleteGroupGearItem(gearData) {
    const response = await fetch(
        `${BASE_URL}/api/delete-gear/${gearData.gearId}`,
        {
            method: "DELETE",
            body: JSON.stringify(gearData.data),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("user-token"),
            },
        }
    );
    const data = await response.json();

    if (!response.ok) {
        throw new Error(JSON.stringify(formatErrors(data)) || "Could not delete group gear item.");
    }
    return data;
}

// ***** Trip Invites *****

export async function acceptTripInvite(inviteToken) {
    const response = await fetch(`${BASE_URL}/api/accept-invite`, {
        method: "POST",
        body: JSON.stringify(inviteToken),
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("user-token"),
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(
            JSON.stringify(formatErrors(data)) || "Could not accept trip invite."
        );
    }
    return null;
}

export async function declineOrLeaveInvite(inviteId) {
    const response = await fetch(`${BASE_URL}/api/decline-invite/${inviteId}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("user-token"),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(
            data.message || "Could not decline or leave an accepted invite."
        );
    }
    return data;
}

// ***** User Settings *****

export async function updateUserProfileImage(selectedImage) {
    const response = await fetch(`${BASE_URL}/api/update/user/profile/image`, {
        method: "POST",
        body: selectedImage,
        headers: {
            dataType: "JSON",
            Authorization: "Bearer " + localStorage.getItem("user-token"),
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(
            JSON.stringify(formatErrors(data)) ||
            "Could not update user profile image."
        );
    }
    return data;
}

export async function changeUserPassword(requestData) {
    const response = await fetch(`${BASE_URL}/api/update/user/password`, {
        method: "PUT",
        body: JSON.stringify(requestData),
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("user-token"),
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(
            JSON.stringify(formatErrors(data)) || "Could not change password."
        );
    }
    return null;
}

export async function getUserDetails() {
    const response = await fetch(`${BASE_URL}/api/user/profile`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("user-token"),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(
            JSON.stringify(formatErrors(data)) || "Could not fetch user's details."
        );
        // throw new Error(data.message || "Could not fetch user's details.");
    }
    return data;
}

export async function updateUserSettings(userData) {
    const response = await fetch(`${BASE_URL}/api/update/user/profile/setting`, {
        method: "PUT",
        body: JSON.stringify(userData),
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("user-token"),
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(
            JSON.stringify(formatErrors(data)) || "Could not update user settings."
        );
    }
    return null;
}

// ***** Form Submissions *****

export async function submitContactForm(formData) {
    const response = await fetch(`${BASE_URL}/api/contact-us`, {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
            "Content-Type": "application/json",
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(
            JSON.stringify(formatErrors(data)) || "Could not submit contact form."
        );
    }
    return null;
}

export async function submitFeedbackForm(feedbackData) {
    const response = await fetch(`${BASE_URL}/api/submit-feedback`, {
        method: "POST",
        body: JSON.stringify(feedbackData),
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("user-token"),
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(
            JSON.stringify(formatErrors(data)) || "Could not submit feedback form."
        );
    }
    return null;
}

//Helper Constants
const MONTHS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

//Helper Functions

export const getDateWithoutTime = (date) => {
    return new Date(
        date.getMonth() + "-" + date.getDate() + "-" + date.getFullYear()
    );
};

export const getDateToStringWithoutTime = (date) => {
    return (
        date.getDate() + " " + MONTHS[date.getMonth()] + " " + date.getFullYear()
    );
};

export const validatePasswordFormat = (pwd) => {
    const lowerCaseLetters = /[a-z]/g;
    const upperCaseLetters = /[A-Z]/g;
    const numbers = /[0-9]/g;
    const specialChars = /[ `!@#$%^&*_+]/;

    return (
        lowerCaseLetters.test(pwd) &&
        upperCaseLetters.test(pwd) &&
        numbers.test(pwd) &&
        specialChars.test(pwd) &&
        pwd.trim().length > 7
    );
};

//Default size allowed is 1MB, and default file type to validate against is image.
//Send these parameters if need to change.

export const validateImage = (
    file,
    allowedSize = "1000001",
    type = "image"
) => {
    const fileExtension = file.name.split(".").pop();
    const allowedExtensions = {
        image: ["png", "jpg", "jpeg"],
        doc: ["docs", "doc", "pdf", "pages", "txt"],
    };

    return (
        allowedExtensions[type].filter((ext) => ext === fileExtension).length > 0 &&
        file.size < allowedSize
    );
};

export const resizeImage = (
    file,
    maxWidth = 700,
    maxHeight = 700,
    compressFormat = "png"
) => {
    return new Promise((resolve) => {
        FileResizer.imageFileResizer(
            file,
            maxWidth,
            maxHeight,
            compressFormat,
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            "file"
        );
    });
};

export async function getSubscriptionPlans() {
    const response = await fetch(`${BASE_URL}/api/subscription-plans`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("user-token"),
        },
    });
    const data = await response.json();

    if (!response.ok) {throw new Error(JSON.stringify(formatErrors(data)) || "Could not fetch subscription details.");}
    return data;
}

export async function SubscribeToPlan(userData) {
    const response = await fetch(`${BASE_URL}/api/subscribe`, {
        method: "POST",
        body: JSON.stringify(userData),
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("user-token"),
        },
    });
    const data = await response.json();

    if (!response.ok) {throw new Error(JSON.stringify(formatErrors(data)) || "Could not fetch user's details.");}
    return data;
}

export async function communityTripsList(params) {
    const query = params ?? '';
    const response = await fetch(`${BASE_URL}/api/community-trips${query}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("user-token"),
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(JSON.stringify(formatErrors(data)) || "Could not fetch all transaction.");
    }
    return data;
}

export async function addNewCommunityTrip(tripData) {
    const response = await fetch(`${BASE_URL}/api/create-community-trip`, {
        method: "POST",
        body: JSON.stringify(tripData),
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("user-token"),
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(
            JSON.stringify(formatErrors(data)) || "Could not create trip."
        );
    }
    return null;
}

export async function getCommunityGearsAgainstTrip(params) {
    const query = params ?? '';
    const response = await fetch(`${BASE_URL}/api/gear-trips-community${query}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("user-token"),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(
            JSON.stringify(formatErrors(data)) || "Could not fetch all users."
        );
    }
    return data;
}

export async function acceptInviteApi(inviteData) {
    const response = await fetch(`${BASE_URL}/api/accept-invite-sms`, {
        method: "POST",
        body: JSON.stringify(inviteData),
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("user-token"),
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(
            JSON.stringify(formatErrors(data)) || "Could not accept invitation."
        );
    }
    return null;
}
