import React, {useEffect, useState} from "react";
import {adminGetAllTrips} from "../../../lib/admin-api";
import {SpinnerModal} from "../../../components/ui/SpinnerModal";
import useHttp from "../../../hooks/use-http";
import {AdminTripsList} from "./AdminTripsList";
import AdminTripsSearch from "./AdminTripsSearch";
import AdminApiErrors from "../../../components/api-errors/AdminApiErrors";

export const AdminAllTrips = () => {

    const [currentPageActive, setCurrentPageActive] = useState(1);
    const [currentPageCompleted, setCurrentPageCompleted] = useState(1);
    const [currentPageCanceled, setCurrentPageCanceled] = useState(1);

    const [searchParams, setSearchParams] = useState({enteredName: "", enteredActivity: "", enteredLocation: "", selectedTripStatus: "", selectedTripVisibility: "", start_date: "", end_date: ""});
    const {sendRequest, status, error, data} = useHttp(adminGetAllTrips);

    useEffect(() => {
        let query = `?pageActive=${currentPageActive}&pageCompleted=${currentPageCompleted}&pageCanceled=${currentPageCanceled}`;
        if (searchParams.enteredName) {
            query += `&name=${encodeURIComponent(searchParams.enteredName)}`;
        }
        if (searchParams.enteredActivity) {
            query += `&activity=${encodeURIComponent(searchParams.enteredActivity)}`;
        }
        if (searchParams.enteredLocation) {
            query += `&location=${encodeURIComponent(searchParams.enteredLocation)}`;
        }
        if (searchParams.selectedTripStatus) {
            query += `&trip_status=${searchParams.selectedTripStatus}`;
        }
        if (searchParams.selectedTripVisibility) {
            query += `&public=${searchParams.selectedTripVisibility}`;
        }
        if (searchParams.start_date) {
            query += `&start_date=${searchParams.start_date}`;
        }
        if (searchParams.end_date) {
            query += `&end_date=${searchParams.end_date}`;
        }
        sendRequest(query);
    }, [sendRequest, currentPageActive, currentPageCompleted, currentPageCanceled, searchParams]);


    const resetSearch = () => {
        setSearchParams({
            enteredName: '',
            enteredActivity: '',
            enteredLocation: '',
            selectedTripStatus: '',
            selectedTripVisibility: '',
            start_date: '',
            end_date: ''
        });
        setCurrentPageActive(1);
        setCurrentPageCompleted(1);
        setCurrentPageCanceled(1);
    }

    // Reset search and current page
    const submitSearch = (data) => {
        setSearchParams({
            enteredName: data.enteredName,
            enteredActivity: data.enteredActivity,
            enteredLocation: data.enteredLocation,
            selectedTripStatus: data.selectedTripStatus,
            selectedTripVisibility: data.selectedTripVisibility,
            start_date: data.start_date,
            end_date: data.end_date
        });
        setCurrentPageActive(1);
        setCurrentPageCompleted(1);
        setCurrentPageCanceled(1);
    }

    const paginationActive = (page) => {
        setCurrentPageActive(page);
    }
    const paginationCompleted = (page) => {
        setCurrentPageCompleted(page);
    }
    const paginationCanceled = (page) => {
        setCurrentPageCanceled(page);
    }

    return (
        <>
            {!error ? (
                <div className="admin-panel-wrap admin-all-trips flex flex-wrap w-100 content-justify-center">
                    <div className="container">
                        <h1>All Trips</h1>
                        <AdminTripsSearch submitSearch={(data) => submitSearch(data)} resetSearch={resetSearch}/>
                        {status === "pending" || status === null ? (
                            <SpinnerModal/>
                        ) : (
                            <>
                                <h3>Active Trips</h3>
                                <div className="table-responsive">
                                    <AdminTripsList
                                        trip={data?.allTrips.active.data}
                                        pagination={(page) => paginationActive(page)}
                                        totalCount={data?.allTrips?.active.total}
                                        currentPage={currentPageActive}
                                    />
                                </div>
                                <h3>Completed Trips</h3>
                                <div className="table-responsive">
                                    <AdminTripsList
                                        trip={data?.allTrips.completed.data}
                                        pagination={(page) => paginationCompleted(page)}
                                        totalCount={data?.allTrips.completed.total}
                                        currentPage={currentPageCompleted}  // Pass the current page
                                    />
                                </div>
                                <h3>Canceled Trips</h3>
                                <div className="table-responsive">
                                    <AdminTripsList
                                        trip={data?.allTrips.canceled.data}
                                        pagination={(page) => paginationCanceled(page)}
                                        totalCount={data?.allTrips.canceled.total}
                                        currentPage={currentPageCanceled}  // Pass the current page
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            ) : (
                <div className="main-profile-detail flex flex-wrap column-direction w-100">
                    <AdminApiErrors>{error}</AdminApiErrors>
                    <p className="no-trip">
                        An error occurred while fetching data from Backend. Please try again later.
                    </p>
                </div>
            )}
        </>
    );
};
