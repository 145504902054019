import React, {useEffect, useState} from "react";
import {adminGetAllTransactionList} from "../../../lib/admin-api";
import {SpinnerModal} from "../../../components/ui/SpinnerModal";
import useHttp from "../../../hooks/use-http";
import {AdminTransactionList} from "./AdminTransactionsList";
import AdminTransactionSearch from "./AdminTransactionSearch";
import AdminApiErrors from "../../../components/api-errors/AdminApiErrors";

export const AdminAllTransactions = () => {
    const [currentPage, setCurrentPage] = useState(1);  // Store the current page
    const [searchParams, setSearchParams] = useState({
        userName: "",
        planName: "",
        selectedVariation: "",
        amount: "",
        session: "",
        selectedTransactionStatus: "",
        start_date: "",
        end_date: "",
    });

    const {sendRequest, status, error, data} = useHttp(adminGetAllTransactionList);

    useEffect(() => {
        let query = `?page=${currentPage}`;
        if (searchParams.userName) {
            query += `&name=${encodeURIComponent(searchParams.userName)}`;
        }
        if (searchParams.planName) {
            query += `&plan=${encodeURIComponent(searchParams.planName)}`;
        }
        if (searchParams.selectedVariation) {
            query += `&duration_type=${searchParams.selectedVariation}`;
        }
        if (searchParams.amount) {
            query += `&amount=${encodeURIComponent(searchParams.amount)}`;
        }
        if (searchParams.session) {
            query += `&session_id=${encodeURIComponent(searchParams.session)}`;
        }
        if (searchParams.selectedTransactionStatus) {
            query += `&status=${searchParams.selectedTransactionStatus}`;
        }
        if (searchParams.start_date) {
            query += `&start_date=${searchParams.start_date}`;
        }
        if (searchParams.end_date) {
            query += `&end_date=${searchParams.end_date}`;
        }
        sendRequest(query);
    }, [sendRequest, currentPage, searchParams]);

    const submitSearch = (data) => {
        setSearchParams({
            userName: data.userName,
            planName: data.planName,
            selectedVariation: data.selectedVariation,
            amount: data.amount,
            session: data.session,
            selectedTransactionStatus: data.selectedTransactionStatus,
            start_date: data.start_date,
            end_date: data.end_date,
        });
        setCurrentPage(1);
    }

    const resetSearch = () => {
        setSearchParams({
            userName: '',
            planName: '',
            selectedVariation: '',
            amount: '',
            session: '',
            selectedTransactionStatus: '',
            start_date: '',
            end_date: '',
        });
        setCurrentPage(1);  // Reset page to 1
    }

    const pagination = (page) => {
        setCurrentPage(page);  // Set the current page in state
    }

    return (
        <>
            {!error ? (
                <div className="admin-panel-wrap admin-all-trips flex flex-wrap w-100 content-justify-center">
                    <div className="container">
                        <h1>Transactions</h1>
                        <AdminTransactionSearch submitSearch={(data) => submitSearch(data)} resetSearch={resetSearch}/>
                        {status === "pending" || status === null ? (
                            <SpinnerModal/>
                        ) : (
                            <>
                                <h3>List</h3>
                                <div className="table-responsive table-responsive-feature">
                                    <AdminTransactionList data={data?.data}
                                                       pagination={(page) => pagination(page)}
                                                       totalCount={data?.total}
                                                       currentPage={currentPage}  // Pass the current page
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            ) : (
                <div className="main-profile-detail flex flex-wrap column-direction w-100">
                    <AdminApiErrors>{error}</AdminApiErrors>
                    <p className="no-trip">
                        An error occurred while fetching data from Backend. Please try again later.
                    </p>
                </div>
            )}
        </>
    );
};
