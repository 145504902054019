import {useCallback} from "react";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";

export const usePermissions = () => {
    const userFeatures = useSelector((state) => state.auth.userFeatures);
    const checkPermission = useCallback(
        async function (key, show = true) {
            if (key && userFeatures.find((x) => x.key === key && x.available)) {
                return true;
            } else {
                show === true ? toast.error("You need to upgrade your plan to access this feature.") : null;
                return false;
            }
        },
        [userFeatures]
    );
    return checkPermission;
}

export const useFeature = (key) => {
    const userFeatures = useSelector((state) => state.auth.userFeatures);
    if (userFeatures.find((x) => x.key === key && x.active === true)) {
        return true;
    } else {
        return true;
    }
}