// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-footer {
  margin-top: auto;
  padding: 15px;
  background-color: #f8f9fa;
  text-align: center;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.start-plan-btn {
  display: inline-block;
  padding: 15px 20px;
  font-size: 14px;
  color: black;
  background-color: #e5b021;
  border-radius: 5px;
  text-decoration: none;
  transition: all 0.3s ease;
}

.start-plan-btn:hover {
  background-color: black;
  color: white;
  transform: scale(1.05);
}

.start-plan-btn:active {
  background-color: #003f7f;
  transform: scale(0.95);
}`, "",{"version":3,"sources":["webpack://./src/pages/community-trips/community-trips.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,aAAA;EACA,yBAAA;EACA,kBAAA;EACA,+BAAA;EACA,gCAAA;AACF;;AAEA;EACE,qBAAA;EACA,kBAAA;EACA,eAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,qBAAA;EACA,yBAAA;AACF;;AAEA;EACE,uBAAA;EACA,YAAA;EACA,sBAAA;AACF;;AAEA;EACE,yBAAA;EACA,sBAAA;AACF","sourcesContent":[".card-footer {\n  margin-top: auto;\n  padding: 15px;\n  background-color: #f8f9fa;\n  text-align: center;\n  border-bottom-left-radius: 15px;\n  border-bottom-right-radius: 15px;\n}\n\n.start-plan-btn {\n  display: inline-block;\n  padding: 15px 20px;\n  font-size: 14px;\n  color: black;\n  background-color: #e5b021;\n  border-radius: 5px;\n  text-decoration: none;\n  transition: all 0.3s ease;\n}\n\n.start-plan-btn:hover {\n  background-color: black;\n  color: white;\n  transform: scale(1.05);\n}\n\n.start-plan-btn:active {\n  background-color: #003f7f;\n  transform: scale(0.95);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
