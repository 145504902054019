import React, {useState} from "react";
import NoGearImage from "../../assets/images/no-gear-img.webp";
import AddPagination from "../../components/ui/AddPagination";
import {getDateToStringWithoutTime} from "../../lib/api";

export const GroupGear = (props) => {
    const allGroupGear = props.groupGear;
    const [paginatedData, setPaginatedData] = useState(allGroupGear);
    const handleError = (e) => {
        e.target.src = NoGearImage;
    };

    return (
        <>
            <div className="side-cards-wrapper">
                <ul className="list-style-none flex column-direction flex-wrap">
                    {allGroupGear.length > 0 && (
                        <>
                            {paginatedData.map((gearItem) => (
                                <li key={gearItem.id}>
                                    <div className="side-cards-wrap">
                                        <img
                                            src={gearItem.item_image || NoGearImage}
                                            alt="img"
                                            onError={handleError}
                                            style={{
                                                width: "80px",
                                                height: "80px",
                                                objectFit: "cover",
                                            }}
                                        />
                                        <section>
                                            <h3>{gearItem.item_name}</h3>
                                            <p>{gearItem.item_description}</p>
                                            <div className="flex gear-items">
                                                <p>
                                                    <strong>
                                                        <i className="fa fa-bar-chart"></i> Quantity:
                                                    </strong>{" "}
                                                    {gearItem.item_quantity}
                                                </p>
                                                <p>
                                                    <strong>
                                                        <i className="fa fa-clock-o"></i> Date:
                                                    </strong>{" "}
                                                    {getDateToStringWithoutTime(
                                                        new Date(gearItem.created_at)
                                                    )}{" "}
                                                </p>
                                            </div>
                                        </section>
                                    </div>
                                </li>
                            ))}
                        </>
                    )}
                    {allGroupGear.length === 0 && (
                        <li className="no-gear">No group gear is added.</li>
                    )}
                </ul>
                <AddPagination
                    allData={allGroupGear}
                    setPaginatedData={setPaginatedData}
                    pageSize={5}
                />
            </div>
        </>
    );
};
