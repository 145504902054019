import multiUserAvatar from "../../assets/images/multi-users.png";
import React from 'react'
import { getInitials } from "../../utilities/NameInitials";


const AttendingUsersAvatars = (props) => {
  let attendingUsers = props.attendingUsers;
  //console.log(attendingUsers);
  //attendingUsers = [...attendingUsers, ...attendingUsers, ...attendingUsers,...attendingUsers,...attendingUsers,...attendingUsers,...attendingUsers,...attendingUsers,...attendingUsers,...attendingUsers];
  return (
    <ul className="list-style-none flex">
      {attendingUsers.map((user, index) => {
        //Only display first 5 attendees.
        let returnValue;
        if (index < 5) {
          returnValue = (
              <li key={index}>
              <span className="tooltip">
              <small>{user.attending_user_name}</small>
                {user.attending_user_image ? (
                    <img
                        src={user.attending_user_image}
                        alt={user.attending_user_name}
                    />
                ) : (
                    // Use initials if the user image is not available
                    <div
                        className="initials-avatar"
                        style={{
                          width: "30px",
                          height: "30px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#ccc", // Example background color
                          color: "#fff", // Example text color
                          borderRadius: "100%", // Make it round
                          fontSize: "12px",
                        }}
                    >
                      {getInitials(user.attending_user_name)}
                    </div>
                )}
            </span>
              </li>
          );
        } else {
          returnValue = null;
        }
        return returnValue;
      })}
      {attendingUsers.length > 5 && (
          <li key={5}>
          <span className="tooltip">
            <small>{attendingUsers.length - 5} Others</small>
            <img src={multiUserAvatar} alt="Not Found"/>
          </span>
          </li>
      )}
    </ul>
  );
};

export default AttendingUsersAvatars;
