import React, {useState} from "react";
import useInput from "../../hooks/use-input";
import Calendar from "react-calendar";
import OutsideClickHandler from "react-outside-click-handler";
import {getDateToStringWithoutTime, getDateWithoutTime} from "../../lib/api";
import {getFormatDate} from "../../lib/admin-api";

const Search = ({submitSearch, resetSearch}) => {
    const [status, setStatus] = useState("");

    const [date, setDate] = useState([new Date()]);
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [isDateFilterActive, setIsDateFilterActive] = useState(false);

    const {
        value: location,
        valueChangeHandler: locationChangeHandler,
        inputBlurHandler: locationBlurHandler,
        resetValue: resetLocation,
    } = useInput((value) => value.trim().length > 0 && value.trim().length < 99);

    const {
        value: activity,
        valueChangeHandler: activityChangeHandler,
        inputBlurHandler: activityBlurHandler,
        resetValue: resetActivity,
    } = useInput((value) => value.trim().length > 0 && value.trim().length < 99);

    const formIsInvalid = false;

    const formSubmitHandler = (event) => {
        event.preventDefault();

        let start_date = "";
        let end_date = "";
        if (isDateFilterActive) {
            start_date = date[0] ? getFormatDate(date[0]) : "";
            end_date = date[1] ? getFormatDate(date[1]) : "";
        } else {
            start_date = "";
            end_date = "";
        }
        if (!formIsInvalid) {
            submitSearch({
                location,
                activity,
                status,
                start_date,
                end_date
            });
        }

        submitSearch({
            location,
            activity,
            status,
            start_date,
            end_date
        });
    };

    const toggleCalendar = () => {
        setIsCalendarOpen(!isCalendarOpen);
    };

    const dateChangeHandler = (selectedDate) => {
        setDate(selectedDate);
        setIsCalendarOpen(false);
    };

    const displayDate = () => {
        if (date[1]) {
            if (
                getDateWithoutTime(date[0]).getTime() ===
                getDateWithoutTime(date[1]).getTime()
            ) {
                setDate([date[0]]);
                return getDateToStringWithoutTime(date[0]);
            } else {
                return (
                    getDateToStringWithoutTime(date[0]) +
                    " - " +
                    getDateToStringWithoutTime(date[1])
                );
            }
        }
        return getDateToStringWithoutTime(date[0]);
    };

    return (
        <div className="admin-search-wrapper">
            <h2 className="search-title">Search</h2>
            <form onSubmit={formSubmitHandler}>
                <ul className="list-style-none flex flex-wrap items-start">
                    <li>
                        <label>Location</label>
                        <input type="text"
                               className="number-list"
                               placeholder="Location"
                               value={location}
                               onChange={locationChangeHandler}
                               onBlur={locationBlurHandler}
                        />
                    </li>
                    <li>
                        <label>Activity</label>
                        <input type="text"
                               className="number-list"
                               placeholder="Activity"
                               value={activity}
                               onChange={activityChangeHandler}
                               onBlur={activityBlurHandler}
                        />
                    </li>
                    <li>
                        <label>Status</label>
                        <select onChange={(event) => {
                            setStatus(event.target.value);
                        }}
                                className="number-list"
                                value={status}
                        >
                            <option value="">All</option>
                            <option value="active">Active</option>
                            <option value="completed">Completed</option>
                        </select>
                    </li>
                    <OutsideClickHandler onOutsideClick={() => {
                        setIsCalendarOpen(false);
                    }}>
                        <li>
                            <input
                                type="checkbox"
                                checked={isDateFilterActive}
                                onChange={() => {
                                    setIsDateFilterActive(!isDateFilterActive);
                                }}
                            />
                            Date Filter
                            <button
                                type="button"
                                className={`number-list ${
                                    !isDateFilterActive && "disabled-link"
                                } ${isCalendarOpen ? "expanded" : ""}`}
                                onClick={toggleCalendar}
                            >
                                <strong>Date / Date Range: </strong>
                                <span>{displayDate()}</span>
                            </button>
                            <div className={`calender-wrap ${isCalendarOpen ? "show" : ""}`}>
                                <Calendar
                                    onChange={dateChangeHandler}
                                    closeCalendar={true}
                                    selectRange={true}
                                />
                            </div>
                        </li>
                    </OutsideClickHandler>
                    <li>
                        <label style={{opacity: '0'}}>Search</label>
                        <button type="submit" className='signup-btn submit-btn'>
                            Search
                        </button>
                    </li>
                    <li>
                        <label style={{opacity: '0'}}>Reset</label>
                        <button onClick={(event) => {
                            event.preventDefault();
                            resetSearch();
                            resetLocation();
                            resetActivity();
                            setDate([new Date()]);
                            setStatus("");
                        }}
                                className="signup-btn submit-btn"
                        >
                            Reset
                        </button>
                    </li>
                </ul>
            </form>
        </div>
    );
};

export default Search;
