import React, {useState} from "react";
import NoGearImage from "../../assets/images/no-gear-img.webp";
import AddPagination from "../../components/ui/AddPagination";
import {getDateToStringWithoutTime} from "../../lib/api";

export const MyGear = (props) => {

    const personalGear = props.personalGear;

    const [paginatedData, setPaginatedData] = useState(personalGear);
    const handleError = (e) => {
        e.target.src = NoGearImage;
    };

    return (
        <>
            <div className="side-cards-wrapper">
                <ul className="list-style-none flex column-direction flex-wrap">
                    {paginatedData.map((gearItem) => (
                        <li key={gearItem.id}>
                            <div className="side-cards-wrap">
                                <img src={gearItem.item_image || NoGearImage} alt="img"
                                     onError={handleError}
                                     style={{width: '80px', height: '80px', objectFit: 'cover'}}
                                />
                                <section>
                                    <h3>{gearItem.item_name}</h3>
                                    <p>{gearItem.item_description}</p>
                                    <div className="flex gear-items">
                                        <p><strong><i
                                            className="fa fa-bar-chart"></i> Quantity:</strong>{" "}{gearItem.item_quantity}
                                        </p>
                                        <p>
                                            <strong>
                                                {" "}
                                                <i className="fa fa-clock-o"></i> Date:
                                            </strong>{" "}
                                            {getDateToStringWithoutTime(
                                                new Date(gearItem.created_at)
                                            )}
                                        </p>
                                    </div>
                                </section>
                            </div>
                        </li>
                    ))}
                    {personalGear.length === 0 && (
                        <li className="no-gear">No personal gear is added.</li>
                    )}
                </ul>
                <AddPagination
                    allData={personalGear}
                    setPaginatedData={setPaginatedData}
                    pageSize={5}
                />
            </div>
        </>
    );
};
