import React from "react";

export const PaymentCancel = () => {
  return (
    <div className="main-trail-head payment-success payment-cancel flex column-direction flex-wrap w-100 content-justify-start">
      <div className="container column-direction">
        <h1><i className="fa fa-window-close" aria-hidden="true"></i> Payment Canceled</h1>
        <p>
          It seems like you canceled the payment. Please try again if you wish
          to subscribe.
        </p>
      </div>
    </div>
  );
};
