import React from "react";
import {Link} from "react-router-dom";
import ServerPagination from "../../../components/ui/ServerPagination";

export const AdminUsersList = ({data, totalCount, currentPage, pagination}) => {
    return (
        <>
            <table className="table">
                <thead>
                <tr>
                    <th>User Id</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {data.map((user) => (
                    <tr key={`user-${user.id}`}>
                        <td>{user.id}</td>
                        <td>
                            <Link to={`/admin-32423QeCFer/user-details/${user.id}`}>
                                {user.name}
                            </Link>
                        </td>
                        <td>{user.email}</td>
                        <td>{user.phone_number}</td>
                        <td>
                            {user.status.charAt(0).toUpperCase() + user.status.slice(1) + `${user.status === "block" ? "ed" : ""}`}
                        </td>
                        <td>
                            <div className="flex content-justify-center items-center">
                                <Link className="table-btn signup-btn submit-btn" to={`/admin-32423QeCFer/user-details/${user.id}`}>
                                    View Details
                                </Link>
                            </div>
                        </td>
                    </tr>
                ))}
                {(data === null || (data && data?.length === 0)) && (
                    <tr>
                        <td colSpan={6}>No Records Found.</td>
                    </tr>
                )}
                </tbody>
            </table>
            {data.length > 0 && (
                <ServerPagination allData={data} getPage={(page) => pagination(page)} totalCount={totalCount} setPage={currentPage}/>
            )}
        </>
    );
};
