import React, {useState} from "react";
import {MyGear} from "./MyGear";
import {GroupGear} from "./GroupGear";

export const TripGearList = (props) => {
    const [activeTab, setActiveTab] = useState("tab1");

    return (
        <>
            {props.gears ? (
                <>
                    <div className="gear-wrapper flex column-direction flex-wrap w-100">
                        <div className="flex">
                            <section className="ml-auto">
                                <button
                                    onClick={() => {
                                        setActiveTab("tab1");
                                    }}
                                    className={`tabs-btn ${
                                        activeTab === "tab1" ? "active" : ""
                                    }`}
                                >
                                    Your Gear
                                </button>
                                <button
                                    onClick={() => {
                                        setActiveTab("tab2");
                                    }}
                                    className={`tabs-btn ${
                                        activeTab === "tab2" ? "active" : ""
                                    }`}
                                >
                                    Group Gear
                                </button>
                            </section>
                        </div>
                        {activeTab === "tab1" ? (
                            <MyGear
                                tripId={props.tripId}
                                personalGear={props.gears.personalGear}
                            />
                        ) : (
                            <GroupGear
                                tripId={props.tripId}
                                groupGear={props.gears.groupGear}
                            />
                        )}
                    </div>
                </>
            ) : (
                <p className="no-trip">Sorry! Trip gear could not be found.</p>
            )}
        </>
    );
};
