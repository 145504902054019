// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Button Styles */
.btn-toggle {
  margin: 0;
  padding: 0;
  position: relative;
  border: none;
  height: 1.5rem;
  width: 3.5rem;
  border-radius: 2.5rem;
  background-color: black;
  transition: background-color 0.25s;
  outline: none;
}

.btn-toggle .handle {
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: #fff;
  transition: left 0.25s;
}

.btn-toggle.active {
  background-color: #e5b021;
}

.btn-toggle.active .handle {
  left: 2.30rem;
}

.btn-lg {
  height: 1.5rem;
  width: 3.5rem;
  .handle { 
    width: 1rem;
    height: 1rem;
  }
}

.td-btn{
    display: flex;
    align-items: center;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/admin-subscription-plans/ToggleSwitch.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;EACE,SAAS;EACT,UAAU;EACV,kBAAkB;EAClB,YAAY;EACZ,cAAc;EACd,aAAa;EACb,qBAAqB;EACrB,uBAAuB;EACvB,kCAAkC;EAClC,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;EACd,aAAa;EACb;IACE,WAAW;IACX,YAAY;EACd;AACF;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":["/* Button Styles */\n.btn-toggle {\n  margin: 0;\n  padding: 0;\n  position: relative;\n  border: none;\n  height: 1.5rem;\n  width: 3.5rem;\n  border-radius: 2.5rem;\n  background-color: black;\n  transition: background-color 0.25s;\n  outline: none;\n}\n\n.btn-toggle .handle {\n  position: absolute;\n  top: 0.25rem;\n  left: 0.25rem;\n  width: 1rem;\n  height: 1rem;\n  border-radius: 50%;\n  background: #fff;\n  transition: left 0.25s;\n}\n\n.btn-toggle.active {\n  background-color: #e5b021;\n}\n\n.btn-toggle.active .handle {\n  left: 2.30rem;\n}\n\n.btn-lg {\n  height: 1.5rem;\n  width: 3.5rem;\n  .handle { \n    width: 1rem;\n    height: 1rem;\n  }\n}\n\n.td-btn{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
