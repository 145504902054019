import React from "react";
import ServerPagination from "../../components/ui/ServerPagination";

export const GearList = ({trip, totalCount, currentPage, pagination}) => {
    return (
        <>
            <table className="table">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Quantity</th>
                    <th>Description</th>
                    <th>Image</th>
                </tr>
                </thead>
                {trip.length > 0 ? (
                    <tbody>
                    {trip.map((trip) => (
                        <tr key={`trip-${trip.id}`}>
                            <td>{trip.item_name}</td>
                            <td>{trip.item_quantity}</td>
                            <td>{trip.item_description}</td>
                            <td>
                                {trip.item_image &&
                                    <img
                                        src={trip.item_image}
                                        alt={trip.item_name}
                                        style={{width: '50px', height: '50px', objectFit: 'cover'}}
                                    />
                                }
                            </td>
                        </tr>
                    ))}
                    </tbody>
                ) : (
                    <tbody>
                    <tr>
                        <td colSpan={5}>No Data Found</td>
                    </tr>
                    </tbody>
                )}
            </table>
            {trip.length > 0 && (
                <ServerPagination allData={trip} getPage={(page) => pagination(page)} totalCount={totalCount} setPage={currentPage}/>
            )}
        </>
    );
};

