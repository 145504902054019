import {
    Route,
    Navigate,
    RouterProvider,
    createRoutesFromElements,
    createHashRouter,
} from "react-router-dom";
import React from 'react'
import SearchBar from "../pages/create-trip/SearchBar";
import TripInvitees from "../pages/create-trip/TripInvitees";
import {SignUp} from "../pages/signup/SignUp";
import UserDashboard from "../pages/user-dashboard/UserDashboard";
import { CommunityTrips } from "../pages/community-trips/index";
import CreateTrip from "../pages/community-trips/Create";
import { Detail } from "../pages/community-trips/Detail";
import {SingleTripDetails} from "../pages/trip-details/SingleTripDetails";
import {UserLayout} from "./user-layout/UserLayout";
import SignIn from "../pages/user-login/SignIn";
import {ContactUs} from "../pages/informational-pages/ContactUs";
import {SignUpEmail} from "../pages/signup/SignUpEmail";
import UserSettings from "../pages/user-settings/UserSettings";
import {VerificationCode} from "../pages/signup/VerificationCode";
import {AdminLayout} from "./admin-layout/AdminLayout";
import {AdminAllUsers} from "../pages/admin/admin-users/AdminAllUsers";
import {AdminAllTrips} from "../pages/admin/admin-trips/AdminAllTrips";
import {AdminAllFeatures} from "../pages/admin/admin-features/AdminAllFeatures";
import {AdminAllTransactions} from "../pages/admin/admin-transactions/AdminAllTransactions";
import {AdminViewTripDetails} from "../pages/admin/admin-trips/AdminViewTripDetails";
import {AdminViewUserDetails} from "../pages/admin/admin-users/AdminViewUserDetails";
import AdminUpdatePassword from "../pages/admin/admin-settings/AdminUpdatePassword";
import {Error} from "../pages/error-page/Error";
import {AdminEditUser} from "../pages/admin/admin-users/AdminEditUser";
import {UpdateTrip} from "../pages/update-trip/UpdateTrip";
import {SplashPage} from "../pages/splash-page/SplashPage";
import {PrivacyPolicy} from "../pages/informational-pages/PrivacyPolicy";
import {About} from "../pages/informational-pages/About";
import {FeedBack} from "../pages/informational-pages/FeedBack";
import {useSelector} from "react-redux";
import AdminLogin from "../pages/admin/admin-login/AdminLogin";
import ForgotPassword from "../pages/user-login/ForgotPassword";
import ResetPassword from "../pages/user-login/ResetPassword";
import SocialLoginPhoneUpdate from "../pages/user-login/SocialLoginPhoneUpdate";
import ProtectedRoute from "./ProtectedRoute";
import OnlyPublicRoute from "./OnlyPublicRoute";
import AdminRoute from "./AdminRoute";
import CheckSocialLoginWithoutPhone from "./CheckSocialLoginWithoutPhone";
import {AdminDashboard} from "../pages/admin/admin-dashboard/index";
import {AdminSubscriptionPlans} from "../pages/admin/admin-subscription-plans/AdminSubscriptionPlans";
import AdminSubscriptionPlansCreate from "../pages/admin/admin-subscription-plans/AdminSubscriptionPlansCreate";
import {SubscriptionPlans} from "../pages/admin/admin-subscription-plans/SubscriptionPlans";
import {AdminSubscribedUsers} from '../pages/admin/admin-subscribed-users/AdminSubscribedUsers'
import {PaymentSuccess} from "../pages/stripe/PaymentSuccess";
import {PaymentCancel} from "../pages/stripe/PaymentCancel";
import {InviteSuccess} from "../pages/acceptInvite/index";

export const Main = () => {
    const isAdminLoggedIn = useSelector(
        (state) => state.adminAuth.isAdminLoggedIn
    );
    const allRoutes = (
        <>
            <Route path="/" element={<UserLayout/>}>
                <Route
                    index
                    element={
                        <CheckSocialLoginWithoutPhone>
                            <SplashPage/>
                        </CheckSocialLoginWithoutPhone>
                    }
                />
            </Route>

            <Route path="/privacy-policy" element={<UserLayout/>}>
                <Route
                    index
                    element={
                        <CheckSocialLoginWithoutPhone>
                            <PrivacyPolicy/>
                        </CheckSocialLoginWithoutPhone>
                    }
                />
            </Route>

            <Route path="/about" element={<UserLayout/>}>
                <Route
                    index
                    element={
                        <CheckSocialLoginWithoutPhone>
                            <About/>
                        </CheckSocialLoginWithoutPhone>
                    }
                />
            </Route>

            <Route path="/payment-success" element={<UserLayout/>}>
                <Route
                    index
                    element={
                        <ProtectedRoute>
                            <PaymentSuccess/>
                        </ProtectedRoute>
                    }
                />
            </Route>

            <Route path="/payment-cancel" element={<UserLayout/>}>
                <Route
                    index
                    element={
                        <ProtectedRoute>
                            <PaymentCancel/>
                        </ProtectedRoute>
                    }
                />
            </Route>

            <Route path="/signin">
                <Route
                    index
                    element={
                        <OnlyPublicRoute>
                            <SignIn/>
                        </OnlyPublicRoute>
                    }
                />
            </Route>

            <Route path="/phone-number-required" element={<UserLayout/>}>
                <Route index element={<SocialLoginPhoneUpdate/>}/>
            </Route>

            <Route path="/forgot-password">
                <Route
                    index
                    element={
                        <OnlyPublicRoute>
                            <ForgotPassword/>
                        </OnlyPublicRoute>
                    }
                />
            </Route>

            <Route path="/reset-password">
                <Route
                    index
                    element={
                        <OnlyPublicRoute>
                            <ResetPassword/>
                        </OnlyPublicRoute>
                    }
                />
            </Route>

            <Route path="/contact" element={<UserLayout/>}>
                <Route
                    index
                    element={
                        <CheckSocialLoginWithoutPhone>
                            <ContactUs/>
                        </CheckSocialLoginWithoutPhone>
                    }
                />
            </Route>

            <Route path="/feedback" element={<UserLayout/>}>
                <Route
                    index
                    element={
                        <ProtectedRoute>
                            <FeedBack/>
                        </ProtectedRoute>
                    }
                />
            </Route>

            <Route path="/signup">
                <Route
                    index
                    element={
                        <OnlyPublicRoute>
                            <SignUp/>
                        </OnlyPublicRoute>
                    }
                />
                <Route
                    path="/signup/email"
                    element={
                        <OnlyPublicRoute>
                            <SignUpEmail/>
                        </OnlyPublicRoute>
                    }
                />
                <Route
                    path="/signup/verification"
                    element={
                        <OnlyPublicRoute>
                            <VerificationCode/>
                        </OnlyPublicRoute>
                    }
                />
            </Route>

            <Route path="/trip-plan" element={<UserLayout/>} errorElement={<SearchBar/>}>
                <Route
                    index
                    element={
                        <ProtectedRoute>
                            <SearchBar/>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/trip-plan/confirm-trip-details"
                    element={
                        <ProtectedRoute>
                            <TripInvitees/>
                        </ProtectedRoute>
                    }
                />
            </Route>

            <Route path="/my-account" element={<UserLayout/>}>
                <Route
                    index
                    element={
                        <ProtectedRoute>
                            <UserDashboard/>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="trip-details/:id"
                    element={
                        <ProtectedRoute>
                            <SingleTripDetails/>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/my-account/settings"
                    element={
                        <ProtectedRoute>
                            <UserSettings/>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="update-trip/:id"
                    element={
                        <ProtectedRoute>
                            <UpdateTrip/>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="choose-plan"
                    element={
                        <ProtectedRoute>
                            <SubscriptionPlans/>
                        </ProtectedRoute>
                    }
                />
            </Route>

            <Route path="/community-trips" element={<UserLayout/>}>
                <Route
                    index
                    element={
                        <ProtectedRoute>
                            <CommunityTrips/>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/community-trips/createTrip/:id/:lat/:lng/:name/:start_date/:end_date/:activity"
                    element={
                        <ProtectedRoute>
                            <CreateTrip />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/community-trips/detail/:id"
                    element={
                        <ProtectedRoute>
                            <Detail />
                        </ProtectedRoute>
                    }
                />
            </Route>

            <Route path="/invite-accept/:token/" element={<UserLayout/>}>
                <Route
                    index
                    element={
                        <CheckSocialLoginWithoutPhone>
                            <InviteSuccess/>
                        </CheckSocialLoginWithoutPhone>
                    }
                />
            </Route>
            {/* Routes for admin side */}

            <Route path="/admin-32423QeCFer/admin-login" element={
                    !isAdminLoggedIn ? (
                        <AdminLogin/>
                    ) : (
                        <Navigate to="/admin-32423QeCFer" replace/>
                    )
                }
            />

            <Route path="/admin-32423QeCFer" element={<AdminLayout/>}>
                <Route
                    index
                    element={
                        <AdminRoute>
                            <AdminDashboard/>
                        </AdminRoute>
                    }
                />
                <Route
                    path="/admin-32423QeCFer/all-users"
                    element={
                        <AdminRoute>
                            <AdminAllUsers/>
                        </AdminRoute>
                    }
                />
                <Route
                    path="/admin-32423QeCFer/all-trips"
                    element={
                        <AdminRoute>
                            <AdminAllTrips/>
                        </AdminRoute>
                    }
                />
                <Route
                    path="/admin-32423QeCFer/all-features"
                    element={
                        <AdminRoute>
                            <AdminAllFeatures/>
                        </AdminRoute>
                    }
                />
                <Route
                    path="/admin-32423QeCFer/subscrubed-users-list/:id"
                    element={
                        <AdminRoute>
                            <AdminSubscribedUsers/>
                        </AdminRoute>
                    }
                />
                <Route
                    path="/admin-32423QeCFer/subscription-plans"
                    element={
                        <AdminRoute>
                            <AdminSubscriptionPlans/>
                        </AdminRoute>
                    }
                />
                <Route
                    path="/admin-32423QeCFer/subscription-plans/create"
                    element={
                        <AdminRoute>
                            <AdminSubscriptionPlansCreate/>
                        </AdminRoute>
                    }
                />
                <Route
                    path="/admin-32423QeCFer/all-transactions"
                    element={
                        <AdminRoute>
                            <AdminAllTransactions/>
                        </AdminRoute>
                    }
                />
                <Route
                    path="user-details/:id"
                    element={
                        <AdminRoute>
                            <AdminViewUserDetails/>
                        </AdminRoute>
                    }
                />
                <Route
                    path="trip-details/:id"
                    element={
                        <AdminRoute>
                            <AdminViewTripDetails/>
                        </AdminRoute>
                    }
                />
                <Route
                    path="/admin-32423QeCFer/update-password"
                    element={
                        <AdminRoute>
                            <AdminUpdatePassword/>
                        </AdminRoute>
                    }
                />
                <Route
                    path="edit-user-details/:id"
                    element={
                        <AdminRoute>
                            <AdminEditUser/>
                        </AdminRoute>
                    }
                />
            </Route>

            <Route path="*" element={<Error/>}/>
        </>
    );
    const router = createHashRouter(createRoutesFromElements(allRoutes));
    return <RouterProvider router={router}/>;
};
