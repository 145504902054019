import React, { useState } from "react";
import modalCross from "../../../assets/images/cross.svg";
import AdminTripCardDropdown from "./AdminTripCardDropdown";
import { getDateToStringWithoutTime } from "../../../lib/api";
import GoogleMapReact from "google-map-react";
import AdminAttendingUsersAvatars from "./AdminAttendingUsersAvatars";

const MapPin = () => <div className="map-pin-text" />;

export const AdminTripCover = (props) => {
  const tripData = props.tripData.trip;
  const attendingUsers = props.tripData.attending_user;

  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => {
    setModalOpen(!modalOpen);
  };
  return (
    <>
      <div className="profile-cover flex flex-wrap w-100">
        <div className="google-map">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_API_KEY,
            }}
            center={{
              lat: +tripData.latitude,
              lng: +tripData.longitude,
            }}
            defaultZoom={12}
          >
            <MapPin
              lat={+tripData.latitude}
              lng={+tripData.longitude}
              pinText="Map"
            />
          </GoogleMapReact>
        </div>
        <div className="container">
          <div className="trip-detail-wrapper">
            <h1>
              {tripData.user_name}'s {tripData.activity} Trip
            </h1>
            <span className="location-wrap">
              <i className="fa fa-map-marker"></i> {tripData.location}
            </span>
            <p>
              <i className="fa fa-calendar-o"></i>{" "}
              {getDateToStringWithoutTime(new Date(tripData.start_date))}{" "}
              {tripData.end_date
                ? `- ${getDateToStringWithoutTime(new Date(tripData.end_date))}`
                : ""}
            </p>
            <AdminAttendingUsersAvatars attendingUsers={attendingUsers} />
            <p>
              {tripData.created_at && (
                <span className="created-date">
                  <i className="fa fa-calendar"></i> {" Created: "}
                  {getDateToStringWithoutTime(new Date(tripData.created_at))}
                </span>
              )}
            </p>
          </div>
          <section className="status_wrapper">
            <span className={`status status-${tripData.status}`}>
              {tripData.status.charAt(0).toUpperCase() +
                tripData.status.slice(1)}
            </span>
            {tripData.public === "true" ? (
              <span>
                <i className="fa fa-eye"></i> Public
              </span>
            ) : (
              <span>
                <i className="fa fa-eye-slash"></i> Private
              </span>
            )}
          </section>
          <AdminTripCardDropdown
            tripId={tripData.trip_id}
            userId={tripData.user_id}
            tripStatus={tripData.status}
            tripPublic={tripData.public}
          />
        </div>
      </div>
      <div
        className={`confirmation-modal modal-wrap ${modalOpen ? "active" : ""}`}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-text">
              <img src={modalCross} onClick={openModal} alt="modalCross" />
              <h2>Are you sure?</h2>
              <div className="button-wrapper">
                <button className="signup-btn no" onClick={openModal}>
                  No
                </button>
                <button className="signup-btn yes" onClick={openModal}>
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// import { useState } from "react";
// import imgOne from "../../../assets/images/1.png";
// import imgTwo from "../../../assets/images/2.png";
// import imgThree from "../../../assets/images/3.png";
// import { Link } from "react-router-dom";
// import info from "../../../assets/images/dots.png";
// import OutsideClickHandler from "react-outside-click-handler";
// import cancelIcon from "../../../assets/images/cancel.png";
// import modalCross from "../../../assets/images/cross.svg";

// export const AdminTripCover = () => {
//   const [isInfoOpen, setIsInfoOpen] = useState(false);
//   const profileOpen = () => {
//     setIsInfoOpen(!isInfoOpen);
//   };
//   const [modalOpen, setModalOpen] = useState(false);
//   const openModal = () => {
//     setModalOpen(!modalOpen);
//   };
//   return (
//     <>
//       <div className="profile-cover flex flex-wrap w-100">
//         <div className="container">
//           <h1>Hiking Trip</h1>
//           <ul className="list-style-none flex">
//             <li>
//               <Link to="#">
//                 <small>John Doe</small>
//                 <img src={imgOne} alt="img" />
//               </Link>
//             </li>
//             <li>
//               <Link to="#">
//                 <small>John Doe</small>
//                 <img src={imgTwo} alt="img" />
//               </Link>
//             </li>
//             <li>
//               <Link to="#">
//                 <small>John Doe</small>
//                 <img src={imgThree} alt="img" />
//               </Link>
//             </li>
//           </ul>
//           <OutsideClickHandler
//             onOutsideClick={() => {
//               setIsInfoOpen(false);
//             }}
//           >
//             <div className="info" onClick={profileOpen}>
//               <img src={info} alt="info" />
//               <div className={`info-detail ${isInfoOpen ? "show" : ""}`}>
//                 <Link to="" onClick={openModal}>
//                   <img src={cancelIcon} alt="" />
//                   Delete Trip
//                 </Link>
//               </div>
//             </div>
//           </OutsideClickHandler>
//         </div>
//       </div>
//       <div
//         className={`confirmation-modal modal-wrap ${modalOpen ? "active" : ""}`}
//       >
//         <div className="modal-dialog">
//           <div className="modal-content">
//             <div className="modal-text">
//               <img src={modalCross} onClick={openModal} alt="modalCross" />
//               <h2>Are you sure?</h2>
//               <div className="button-wrapper">
//                 <button className="signup-btn no" onClick={openModal}>
//                   No
//                 </button>
//                 <button className="signup-btn yes" onClick={openModal}>
//                   Yes
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };
