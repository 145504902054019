import React from 'react';
import {getDateToStringWithoutTime} from "../../../lib/api";
import "../popUpModals.scss";

const TransactionLogModal = ({ isOpen, onClose, logs }) => {

    if (!isOpen) return null;

    return (
        <div className="confirm-modal">
            <div className="confirm-modal-content transaction-modal">
                <div className="modal-header">
                    <h2>Transaction Logs</h2>
                </div>
                <div className="modal-body">
                    <div className="transaction-logs">
                        {logs && logs.length > 0 ? (
                            <table className="logs-table">
                                <thead>
                                <tr>
                                    <th>Log ID</th>
                                    <th>Stripe Event</th>
                                    <th>Description</th>
                                    <th>Status</th>
                                    <th>Date</th>
                                </tr>
                                </thead>
                                <tbody>
                                {logs.map((log) => (
                                    <tr key={log.id} className="tableLog">
                                        <td>{log.id}</td>
                                        <td>{log.event_name}</td>
                                        <td>{log.description}</td>
                                        <td>{log?.status ? log.status.charAt(0).toUpperCase() + log.status.slice(1) : '-'}</td>
                                        <td>{getDateToStringWithoutTime(new Date(log.created_at))}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        ) : (
                            <p className='no-logs'>No logs found.</p>
                        )}
                    </div>
                </div>
                <div className="modal-footer flex content-justify-end">
                    <button className="signup-btn variationModalBtn" onClick={onClose}>
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TransactionLogModal;
