import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import "./PaymentLayout.scss";

export const PaymentSuccess = () => {
  const location = useLocation();
  const sessionId = new URLSearchParams(location.search).get("session_id");
  const rawAmount = new URLSearchParams(location.search).get("amount");
  const currency = new URLSearchParams(location.search).get("currency");
  const datetime = new URLSearchParams(location.search).get("date");
  const [tooltip, setTooltip] = useState("");
  const usdamount = Number(rawAmount) / 100;
  const handleCopy = () => {
    navigator.clipboard.writeText(sessionId).then(() => {
      setTooltip("Copied!");
      setTimeout(() => setTooltip(""), 2000);
    });
  };
  const handlePrintToPDF = () => {
    window.print();
  };

  useEffect(() => {
    
  }, [sessionId, usdamount, currency, datetime]);

  return (
    <div className="main-trail-head payment-success flex column-direction flex-wrap w-100 content-justify-start">
      <div className="container column-direction">
        <div className="payment-success-container">
          <div className="payment-success-header">
            <i className="fas fa-check-circle"></i>
            <h1>Payment Success!</h1>
            <p>USD {usdamount}</p>
          </div>
          <div className="payment-details">
            <h2>Payment Details</h2>
            <table className="payment-table">
              <tbody>
                <tr>
                  <td>Payment Status:</td>
                  <td>
                    <i className="fas fa-check-circle"></i> Success
                  </td>
                </tr>
                <tr>
                  <td>Payment Time:</td>
                  <td>{datetime}</td>
                </tr>
                <tr>
                  <td>Total Payment:</td>
                  <td>USD {usdamount}</td>
                </tr>
              </tbody>
            </table>
            <div className="session-id">
              <label>
                <strong>Session ID:</strong>
              </label>
              <span>{sessionId}</span>
              <button
                onClick={handleCopy}
                className="copy-button"
                aria-label="Copy session ID to clipboard"
              >
                <i className="fas fa-copy copy-icon"></i>
                {tooltip && <span className="tooltip">{tooltip}</span>}
              </button>
            </div>
          </div>
          <div className="user-instructions">
            <h3>Instructions</h3>
            <ul>
              <li>
                <strong>Your subscription is now active!</strong>
              </li>
              <li>
                <strong>Updates might take a few minutes</strong> — please be
                patient.
              </li>
              <li>
                <strong>Check your email</strong> for a confirmation message.
              </li>
              <li>
                <strong>For a smoother experience, Log out and back in</strong>{" "}
                to apply the changes.
              </li>
            </ul>
          </div>
          <div className="trouble-section">
            <h3>
              <i className="fas fa-exclamation-circle"></i>Trouble With Your
              Payment?
            </h3>
            <p>
              Get in touch via our{" "}
              <Link to="/contact" className="contact-link">
                Contact page!
              </Link>
            </p>
          </div>
          <div className="print-pdf-button">
            <button onClick={handlePrintToPDF} className="print-button">
              <i className="fas fa-file-pdf"></i> Get PDF Receipt
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
