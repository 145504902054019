import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import GoogleMapReact from "google-map-react";
import hikingIcon from "../../assets/images/hiking.svg";
import {useNavigate, useParams} from "react-router-dom";
import {newTripActions} from "../../store/new-trip-slice";
import useHttp from "../../hooks/use-http";
import {addNewCommunityTrip, getDateToStringWithoutTime} from "../../lib/api";
import SpinnerButton from "../../components/ui/SpinnerButton";
import ApiErrors from "../../components/api-errors/ApiErrors";
import PhoneNumbers from "./Phone";

const MapPin = () => <div className="map-pin-text"/>;

const CreateTrip = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {sendRequest, status, error} = useHttp(addNewCommunityTrip);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [showApiErrors, setShowApiErrors] = useState(false);
    const [publicTrip, setPublicTrip] = useState("true");

    const phoneNumbersList = useSelector((state) => state.newTrip.invitees_community);
    const {id, lat, lng, name, start_date, end_date, activity} = useParams();

    // Format the display date
    const displayDate = `${getDateToStringWithoutTime(new Date(start_date))} ${end_date !== 'null' ? `- ${getDateToStringWithoutTime(new Date(end_date))}` : ""}`;

    // Handle trip creation submission
    const signupClickHandler = () => {
        setShowApiErrors(false);
        if (phoneNumbersList.length === 0) {
            setShowErrorModal(true);
        } else {
            createTripHandler();
        }
    };

    // Create trip handler
    const createTripHandler = async () => {
        const phoneNumbersWithoutIDs = phoneNumbersList.map((phone) => phone.number);
        const tripData = {
            id: id,
            activity,
            phone_numbers: phoneNumbersWithoutIDs,
            location: name,
            start_date,
            end_date: end_date !== 'null' ? end_date : null,
            latitude: lat,
            longitude: lng,
            public: publicTrip,
        };
        sendRequest(tripData, true);
    };

    // Handle successful trip creation or error
    useEffect(() => {
        if (status === "completed" && !error) {
            dispatch(newTripActions.resetAllTripCommunityData());
            navigate("/my-account", {replace: true});
        }
    }, [status, error, dispatch, navigate]);

    // Handle API error display
    useEffect(() => {
        if (error) {
            setShowApiErrors(true);
            setTimeout(() => setShowApiErrors(false), 5000);
        }
    }, [error]);

    return (
        <div className="main-trail-head confirm-trip flex flex-wrap w-100 content-justify-center">
            <div className="container">
                <div className="trail-head-wrapper">
                    <h1 className="trail-title">Your "{activity}" Trip</h1>

                    <section className="trip-date">
                        <span><i className="fa fa-calendar"></i> {displayDate}</span>
                        <span><i className="fa fa-map-marker"></i> {name}</span>
                    </section>

                    <div className="map-wrapper">
                        <GoogleMapReact
                            bootstrapURLKeys={{key: process.env.REACT_APP_GOOGLE_API_KEY}}
                            center={{lat: Number(lat), lng: Number(lng)}}
                            defaultZoom={11}
                        >
                            <MapPin lat={lat} lng={lng} pinText="Map"/>
                        </GoogleMapReact>
                        <div className="notification">
                            <img src={hikingIcon} alt="Hiking"/>
                            <span>{activity}</span>
                        </div>
                    </div>

                    {/* Public/Private trip toggle */}
                    <label htmlFor="active_private" className="switch">
                        <input
                            type="checkbox"
                            id="active_private"
                            checked={publicTrip === "true"}
                            onChange={() => setPublicTrip(prev => (prev === "true" ? "false" : "true"))}
                        />
                        <div className="slider round"></div>
                        <span>{publicTrip === "true" ? "Trip is public" : "Trip is private"}</span>
                    </label>

                    {/* Phone numbers section */}
                    <h2 className="trail-title">Who's joining?</h2>
                    <PhoneNumbers
                        setPhoneNumberError={setPhoneNumberError}
                        phoneNumbersList={phoneNumbersList}
                    />
                    {phoneNumberError && (
                        <span className="error-msg">
              Please enter a valid phone number with 10 digits or check if the number is a duplicate.
            </span>
                    )}

                    <p>You can optionally add/remove phone numbers here to send trip invites!</p>

                    {/* API error display */}
                    {showApiErrors && <ApiErrors>{error}</ApiErrors>}

                    {/* Modal for no invitees */}
                    {showErrorModal && (
                        <div className="confirm-modal">
                            <div className="confirm-modal-content">
                                <h2>Are you sure you would not like to invite anyone for the trip?</h2>
                                <section>
                                    <button className="signup-btn hover-btn" onClick={() => setShowErrorModal(false)}>
                                        Invite Friends
                                    </button>
                                    <button className="signup-btn" onClick={createTripHandler}>
                                        YES
                                    </button>
                                </section>
                            </div>
                        </div>
                    )}

                    {/* Create trip button */}
                    <button
                        className={`signup-btn addCreateTrip ${status === "pending" ? "disabled-link" : ""}`}
                        onClick={signupClickHandler}
                    >
                        Create Trip {status === "pending" && <SpinnerButton/>}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CreateTrip;
