// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.show-desktop {
  display: block;
}

.show-mobile {
  margin-top: 10px;
  display: none;
}

@media (max-width: 900px) {
  .show-desktop {
    display: none;
  }
  .show-mobile {
    display: block;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/community-trips/community-trip-detail.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EACE,gBAAA;EACA,aAAA;AACF;;AAEA;EACE;IACE,aAAA;EACF;EACA;IACE,cAAA;EACF;AACF","sourcesContent":[".show-desktop {\n  display: block;\n}\n\n.show-mobile {\n  margin-top: 10px;\n  display: none;\n}\n\n@media (max-width: 900px) {\n  .show-desktop {\n    display: none;\n  }\n  .show-mobile {\n    display: block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
