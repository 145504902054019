import "./about.scss";
import React from 'react'

export const About = () => {
    return (
        <div className="about-wrapper">
            <div className="container">
                <h1>About</h1>
                <p>
                    At Inspire Outdoors, we believe it’s time to step out of comfort zones and into the adventures we’ve
                    all been dreaming of.
                </p>
                <p>
                    It all started with a simple idea: bringing friends together to effortlessly plan and execute
                    absolutely epic adventures. We noticed that while many people crave extraordinary outdoor
                    experiences, the complexities of planning and coordinating often hold them back. The best stories
                    aren’t just about the destinations but about the people we share them with and the memories we
                    create together.
                </p>
                <p>
                    That’s why we founded Inspire Outdoors—to make adventure planning simple and enjoyable. We’re
                    committed to providing intuitive tools and resources that enhance every aspect of your journey. No
                    more frustrating logistics or endless back-and-forths. Instead, we offer an easy, streamlined
                    process that gets you and your friends out there, exploring and inspiring each other.
                </p>
                <p>
                    But we’re more than just a planning platform.
                </p>
                <p>
                    We’re building a community of like-minded adventurers who motivate and inspire one another—a place
                    where stories are shared, friendships are forged, and the spirit of exploration thrives. Whether
                    you’re a seasoned trailblazer or new to the outdoors, we’re here to bring people together and make
                    every journey extraordinary.
                </p>
            </div>
        </div>
    );
};
