import React, {useEffect, useState} from "react";
import {getCommunityGearsAgainstTrip} from "../../lib/api";
import {SpinnerModal} from "../../components/ui/SpinnerModal";
import useHttp from "../../hooks/use-http";
import {GearList} from "./Gear";
import SearchGear from "./SearchGear";
import ApiErrors from "../../components/api-errors/ApiErrors";
import {useParams} from "react-router-dom";

export const AllGearList = () => {

    const [currentPagePersonal, setCurrentPagePersonal] = useState(1);
    const [currentPageGroup, setCurrentPageGroup] = useState(1);

    const [searchParams, setSearchParams] = useState({name: ""});
    const {sendRequest, status, error, data} = useHttp(getCommunityGearsAgainstTrip);

    const {id} = useParams();

    useEffect(() => {
        let query = `?pagePersonal=${currentPagePersonal}&pageGroup=${currentPageGroup}`;
            query += `&trip_id=${id}`;
        if (searchParams.name) {
            query += `&name=${encodeURIComponent(searchParams.name)}`;
        }
        sendRequest(query);
    }, [sendRequest, currentPagePersonal, currentPageGroup, searchParams]);


    const resetSearch = () => {
        setSearchParams({
            name: ''
        });
        setCurrentPagePersonal(1);
        setCurrentPageGroup(1);
    }

    // Reset search and current page
    const submitSearch = (data) => {
        setSearchParams({
            name: data.name
        });
        setCurrentPagePersonal(1);
        setCurrentPageGroup(1);
    }

    const paginationPersonal = (page) => {
        setCurrentPagePersonal(page);
    }
    const paginationGroup = (page) => {
        setCurrentPageGroup(page);
    }

    return (
        <>
            {!error ? (
                <div className="admin-panel-wrap admin-all-trips flex flex-wrap w-100 content-justify-center">
                    <div className="container">
                        <h1>All Trips</h1>
                        <SearchGear submitSearch={(data) => submitSearch(data)} resetSearch={resetSearch}/>
                        {status === "pending" || status === null ? (
                            <SpinnerModal/>
                        ) : (
                            <>
                                <h3>Personal Gear</h3>
                                <div className="table-responsive">
                                    <GearList
                                        trip={data?.allGears.personalGear.data}
                                        pagination={(page) => paginationPersonal(page)}
                                        totalCount={data?.allGears?.personalGear.total}
                                        currentPage={currentPagePersonal}
                                    />
                                </div>
                                <h3>Group Gear</h3>
                                <div className="table-responsive">
                                    <GearList
                                        trip={data?.allGears.groupGear.data}
                                        pagination={(page) => paginationGroup(page)}
                                        totalCount={data?.allGears.groupGear.total}
                                        currentPage={currentPageGroup}  // Pass the current page
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            ) : (
                <div className="main-profile-detail flex flex-wrap column-direction w-100"
                     style={{textAlign: 'center'}}>
                    <ApiErrors>{error}</ApiErrors>
                </div>
            )}
        </>
    );
};
