import React, { useState } from "react";
import useInput from "../../../hooks/use-input";
import Calendar from "react-calendar";
import OutsideClickHandler from "react-outside-click-handler";

import {
  getDateToStringWithoutTime,
  getDateWithoutTime,
} from "../../../lib/api";
import {
  getFormatDate
} from "../../../lib/admin-api";

const AdminTripsSearch = ({submitSearch, resetSearch}) => {
  const [selectedTripStatus, setSelectedTripStatus] = useState("");
  const [selectedTripVisibility, setSelectedTripVisibility] = useState("");
  const [date, setDate] = useState([new Date()]);

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isDateFilterActive, setIsDateFilterActive] = useState(false);

  const {
    value: enteredName,
    valueChangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
    resetValue: resetName,
  } = useInput((value) => value.trim().length > 0 && value.trim().length < 99);

  const {
    value: enteredActivity,
    valueChangeHandler: activityChangeHandler,
    inputBlurHandler: activityBlurHandler,
    resetValue: resetActivity,
  } = useInput((value) => value.trim().length > 0 && value.trim().length < 99);

  const {
    value: enteredLocation,
    valueChangeHandler: locationChangeHandler,
    inputBlurHandler: locationBlurHandler,
    resetValue: resetLocation,
  } = useInput((value) => value.trim().length > 0 && value.trim().length < 99);

  const formIsInvalid = false;

  const formSubmitHandler = (event) => {
    event.preventDefault();
    let start_date = "";
    let end_date = "";
    if (isDateFilterActive) {
      start_date = date[0] ? getFormatDate(date[0]) : "";
      end_date = date[1] ? getFormatDate(date[1]) : "";
    } else {
      start_date = "";
      end_date = "";
    }
    if (!formIsInvalid) {
      submitSearch({
        enteredName,
        enteredActivity,
        enteredLocation,
        selectedTripStatus,
        selectedTripVisibility,
        start_date,
        end_date
      });
    }
  };

  const toggleCalendar = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  const dateChangeHandler = (selectedDate) => {
    setDate(selectedDate);
    setIsCalendarOpen(false);
  };

  const displayDate = () => {
    if (date[1]) {
      if (
          getDateWithoutTime(date[0]).getTime() ===
          getDateWithoutTime(date[1]).getTime()
      ) {
        setDate([date[0]]);
        return getDateToStringWithoutTime(date[0]);
      } else {
        return (
            getDateToStringWithoutTime(date[0]) +
            " - " +
            getDateToStringWithoutTime(date[1])
        );
      }
    }
    return getDateToStringWithoutTime(date[0]);
  };

  return (
      <div className="admin-search-wrapper">
        <h2 className="search-title">Search</h2>
        <form onSubmit={formSubmitHandler}>
          <ul className="list-style-none flex flex-wrap items-start">
            <li>
              <input
                  type="text"
                  className="number-list"
                  placeholder="User Name"
                  value={enteredName}
                  onChange={nameChangeHandler}
                  onBlur={nameBlurHandler}
              />
            </li>
            <li>
              <input
                  type="text"
                  className="number-list"
                  placeholder="Activity"
                  value={enteredActivity}
                  onChange={activityChangeHandler}
                  onBlur={activityBlurHandler}
              />
            </li>
            <li>
              <input
                  type="text"
                  className="number-list"
                  placeholder="Location"
                  value={enteredLocation}
                  onChange={locationChangeHandler}
                  onBlur={locationBlurHandler}
              />
            </li>
            <OutsideClickHandler
                onOutsideClick={() => {
                  setIsCalendarOpen(false);
                }}
            >
              <li>
                <input
                    type="checkbox"
                    checked={isDateFilterActive}
                    onChange={() => {
                      setIsDateFilterActive(!isDateFilterActive);
                    }}
                />
                Date Filter
                <button
                    type="button"
                    className={`number-list ${
                        !isDateFilterActive && "disabled-link"
                    } ${isCalendarOpen ? "expanded" : ""}`}
                    onClick={toggleCalendar}
                >
                  <strong>Date / Date Range: </strong>
                  <span>{displayDate()}</span>
                </button>
                <div className={`calender-wrap ${isCalendarOpen ? "show" : ""}`}>
                  <Calendar
                      onChange={dateChangeHandler}
                      closeCalendar={true}
                      selectRange={true}
                  />
                </div>
              </li>
            </OutsideClickHandler>
            <li>
              <label>Trip Status</label>
              <select
                  onChange={(event) => {
                    setSelectedTripStatus(event.target.value);
                  }}
                  className="number-list"
                  value={selectedTripStatus}
              >
                <option value="">All</option>
                <option value="active">Active</option>
                <option value="completed">Completed</option>
                <option value="canceled">Canceled</option>
              </select>
            </li>
            <li>
              <label>Trip Visibility</label>
              <select
                  onChange={(event) => {
                    setSelectedTripVisibility(event.target.value);
                  }}
                  className="number-list"
                  value={selectedTripVisibility}
              >
                <option value="All">All</option>
                <option value="true">Public</option>
                <option value="false">Private</option>
              </select>
            </li>
            <li>
              <button type="submit" className='signup-btn submit-btn'>
                Search
              </button>
            </li>

            <li>
              <button
                  onClick={(event) => {
                    event.preventDefault();
                    resetSearch();
                    resetName();
                    resetActivity();
                    resetLocation();
                    setDate([new Date()]);
                    setSelectedTripStatus("");
                    setSelectedTripVisibility("");
                    setIsDateFilterActive(false);
                  }}
                  className={"signup-btn submit-btn"}
              >
                Reset
              </button>
            </li>
          </ul>
        </form>
      </div>
  );
};

export default AdminTripsSearch;

