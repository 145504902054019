import { useDispatch } from "react-redux";
import { newTripActions } from "../../store/new-trip-slice";
import { usePlacesWidget } from "react-google-autocomplete";
import React, { useEffect, useState } from 'react';
import './Location.css';

export const Location = (props) => {
    const dispatch = useDispatch();
    const [showErrorModal, setShowErrorModal] = useState(false);

    const { ref } = usePlacesWidget({
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        onPlaceSelected: (place) => {
            if (!place.geometry) {
                setShowErrorModal(true);
                return;
            }

            const lat = Math.round(place.geometry.location.lat() * 10000) / 10000;
            const lng = Math.round(place.geometry.location.lng() * 10000) / 10000;

            // Using both place.name and place.formatted_address for more specific information
            const selectedPlace = {
                lat: lat,
                lng: lng,
                placeName: place.name ? `${place.name}, ${place.formatted_address}` : place.formatted_address,
            };

            if (props.isEditing) {
                props.setLocationHasError(false);
                props.setLocation(selectedPlace);
            } else {
                dispatch(newTripActions.updateLocation(selectedPlace));
                localStorage.setItem("lat", selectedPlace.lat);
                localStorage.setItem("lng", selectedPlace.lng);
                localStorage.setItem("location", selectedPlace.placeName);
                props.updateLocationSelected(true);
            }
        },
        options: {
            types: [],
            fields: ['address_components', 'formatted_address', 'geometry', 'name'],
        }
    });

    const locationChangeHandler = () => {
        props.setLocationTouched(true);
        if (ref.current.value.trim() === "") {
            {
                props.isEditing
                    ? props.setLocationHasError(true)
                    : props.updateLocationSelected(false);
            }
        }
    };

    const handleOkButtonClick = () => {
        setShowErrorModal(false);
    };
    useEffect(() => {
        // Add a class to the body when this component mounts
        document.body.classList.add('location-autocomplete-active');

        // Remove the class when the component unmounts
        return () => {
            document.body.classList.remove('location-autocomplete-active');
        };
    }, []);

    return (
        <>
            <div className={`field-wrapper ${props.className}`}>
                <form>
                    <strong>Location</strong>
                    <input
                        type="text"
                        ref={ref}
                        defaultValue={props.location && props.location.placeName} // Only works when editing.
                        placeholder="Type Location..."
                        className="number-list"
                        onChange={locationChangeHandler}
                        onBlur={locationChangeHandler}
                    />
                </form>
            </div>
            {showErrorModal && (
                <div className="confirm-modal">
                    <div className="confirm-modal-content">
                        <h2>Please select a valid location from the dropdown.</h2>
                        <section>
                            <button className="signup-btn hover-btn" onClick={handleOkButtonClick}>OK</button>
                        </section>
                    </div>
                </div>
            )}
        </>
    );
};

