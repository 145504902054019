import React from "react";
import { getDateToStringWithoutTime } from "../../lib/api";
import GoogleMapReact from "google-map-react";

const MapPin = () => <div className="map-pin-text" />;

export const DetailCover = (props) => {
    return (
        <>
            <div className="profile-cover flex flex-wrap w-100">
                <div className="google-map">
                    <GoogleMapReact
                        bootstrapURLKeys={{
                            key: process.env.REACT_APP_GOOGLE_API_KEY,
                        }}
                        center={{
                            lat: +props.latitude,
                            lng: +props.longitude,
                        }}
                        defaultZoom={12}
                    >
                        <MapPin
                            lat={+props.latitude}
                            lng={+props.longitude}
                            pinText="Map"
                        />
                    </GoogleMapReact>
                </div>
                <div className="container">
                    <div className="trip-detail-wrapper">
                        <h1>{props.activity} Trip </h1>
                        <span className="location-wrap"><i className="fa fa-map-marker"></i> {props.location}</span>
                        <p><i className="fa fa-calendar-o"></i>{" "}
                            {getDateToStringWithoutTime(new Date(props.start_date))}{" "}
                            {props.end_date
                                ? `- ${getDateToStringWithoutTime(new Date(props.end_date))}`
                                : ""}
                        </p>
                        <p>
                            {props.created_at && (
                                <span className="created-date"><i className="fa fa-calendar"></i> {" Created: "}
                                    {getDateToStringWithoutTime(new Date(props.created_at))}
                                </span>
                            )}
                        </p>
                    </div>
                    <section className="status_wrapper">
                        <span className={`status status-${props.status}`}>{props.status.charAt(0).toUpperCase() + props.status.slice(1)}</span>
                        {props.public === "true" ? (
                            <span><i className="fa fa-eye"></i> Public </span>
                        ) : (
                            <span><i className="fa fa-eye-slash"></i> Private </span>
                        )}
                    </section>
                </div>
            </div>
        </>
    );
};
