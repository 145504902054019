import React from "react";
import "./ToggleSwitch.css";
import ServerPagination from "../../../components/ui/ServerPagination";

export const AdminFeaturesList = ({data, action, disable, totalCount, currentPage, pagination}) => {
    return (
        <>
            <table className="table">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Type</th>
                    <th>Status</th>
                </tr>
                </thead>
                {data.length > 0 ? (
                    <tbody>
                    {data.map((item) => (
                        <tr key={`feature-${item.id}`}>
                            <td>{item.name}</td>
                            <td>{item.description ?? "-"}</td>
                            <td>
                                {item.type.charAt(0).toUpperCase() + item.type.slice(1)}
                            </td>
                            <td className="td-btn">
                                <button
                                    className={`btn btn-lg btn-toggle ${item.is_active === 1 ? "active" : ""}`}
                                    onClick={() => action(item)}
                                    disabled={disable === "pending"}
                                >
                                    <div className="handle"></div>
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                ) : (
                    <tbody>
                    <tr>
                        <td colSpan={6}>No Data Found</td>
                    </tr>
                    </tbody>
                )}
            </table>
            {data.length > 0 && (
                <ServerPagination allData={data} getPage={(page) => pagination(page)} totalCount={totalCount} setPage={currentPage}/>
            )}
        </>
    );
};
