import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  location: {
    lat: +localStorage.getItem("lat"),
    lng: +localStorage.getItem("lng"),
    placeName: localStorage.getItem("location"),
  },
  dateRange: JSON.parse(localStorage.getItem("date"))
    ? JSON.parse(localStorage.getItem("date"))
    : [],
  activity: localStorage.getItem("activity"),
  invitees: [],
  invitees_community: [],
};

const newTripSlice = createSlice({
  name: "newTrip",
  initialState,
  reducers: {
    updateLocation(state, action) {
      state.location = action.payload;
    },
    updateDateRange(state, action) {
      state.dateRange = action.payload;
    },
    updateActivity(state, action) {
      state.activity = action.payload;
    },
    addInvitee(state, action) {
      state.invitees = [...state.invitees, action.payload];
    },
    addInviteeCommunity(state, action) {
      state.invitees_community = [...state.invitees_community, action.payload];
    },
    removeInvitee(state, action) {
      state.invitees = state.invitees.filter(
        (invitee) => invitee.id !== action.payload
      );
    },
    removeInviteeCommunity(state, action) {
      state.invitees_community = state.invitees_community.filter(
          (invitee) => invitee.id !== action.payload
      );
    },
    resetAllTripData(state) {
      state.location = null;
      state.dateRange = [];
      state.activity = null;
      state.invitees = [];
      localStorage.removeItem("location");
      localStorage.removeItem("date");
      localStorage.removeItem("lat");
      localStorage.removeItem("lng");
      localStorage.removeItem("invitees");
      localStorage.removeItem("activity");
    },
    resetAllTripCommunityData(state) {
      state.invitees_community = [];
      localStorage.removeItem("invitees_community");
    },
  },
});

export const newTripActions = newTripSlice.actions;
export default newTripSlice.reducer;
