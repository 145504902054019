import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import { DetailCover } from "./DetailCover";
import { TripGearList } from "./TripGearList";
import {getCommunityGearsAgainstTrip} from "../../lib/api";
import useHttp from "../../hooks/use-http";
import {SpinnerModal} from "../../components/ui/SpinnerModal";
import ApiErrors from "../../components/api-errors/ApiErrors";
import {Link} from "react-router-dom";
import './community-trip-detail.scss';

export const Detail = () => {

    const {id} = useParams();
    const {sendRequest, status, error, data} = useHttp(getCommunityGearsAgainstTrip);

    useEffect(() => {
        let query = `?trip_id=${id}`;
        sendRequest(query);
    }, [sendRequest]);

    return (
        <>
            {!error ? (
                <>
                    {status === "pending" || status === null ? (
                        <SpinnerModal/>
                    ) : (
                        <>
                            <div className="main-profile-detail flex flex-wrap column-direction w-100">
                                <DetailCover
                                    latitude={data.gears.trip.latitude}
                                    longitude={data.gears.trip.longitude}
                                    location={data.gears.trip.location}
                                    activity={data.gears.trip.activity}
                                    start_date={data.gears.trip.start_date}
                                    end_date={data.gears.trip.end_date}
                                    created_at={data.gears.trip.created_at}
                                    status={data.gears.trip.status}
                                    public={data.gears.trip.public}
                                />

                                <div className="container">
                                    <div className="panel-wrap flex">
                                        <div className="sidebar-wrap">
                                            <div className="sidebar-wrapper flex flex-wrap">
                                                <h2 className="side-title">Trip Description</h2>
                                                {data.gears.trip.description !== null ? (
                                                    <p>{data.gears.trip.description}</p>
                                                ) : (
                                                    <p>
                                                        No description is added for this trip.
                                                    </p>
                                                )}
                                            </div>
                                            <div className="show-desktop">
                                                <Link
                                                    to={`/community-trips/createTrip/${id}/${data.gears.trip.latitude}/${data.gears.trip.longitude}/${data.gears.trip.location}/${data.gears.trip.start_date}/${data.gears.trip.end_date}/${data.gears.trip.activity}`}
                                                    className="start-plan-btn">
                                                    Start From this Plan
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="gear-wrapper">
                                            <TripGearList tripId={id} gears={data.gears}/>
                                        </div>
                                    </div>
                                    <div className="show-mobile">
                                        <Link
                                            to={`/community-trips/createTrip/${id}/${data.gears.trip.latitude}/${data.gears.trip.longitude}/${data.gears.trip.location}/${data.gears.trip.start_date}/${data.gears.trip.end_date}/${data.gears.trip.activity}`}
                                            className="start-plan-btn">
                                            Start From this Plan
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </>
            ) : (
                <div className="main-profile-detail flex flex-wrap column-direction w-100">
                    <ApiErrors>{error}</ApiErrors>
                    <p className="no-trip">
                        An error occured while fetching data from Backend. Please try again
                        later.
                    </p>
                </div>
            )}
        </>
    );
};
