import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import info from "../../../assets/images/dots.png";
import ModalWithState from "../../../components/ui/ModalWithState";
import ModalWithInfo from "../../../components/ui/ModalWithInfo";
import useHttp from "../../../hooks/use-http";
import { adminDeleteTrip } from "../../../lib/admin-api";
import AdminApiErrors from "../../../components/api-errors/AdminApiErrors";

const AdminTripCardDropdown = (props) => {
  const navigate = useNavigate();
  const tripId = props.tripId;
  const userId = props.userId;

  const {
    sendRequest: sendDeleteRequest,
    status: deleteStatus,
    error: deleteError,
  } = useHttp(adminDeleteTrip);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [actionType, setActionType] = useState("");
  const [showActionModal, setShowActionModal] = useState(false);
  const [actionConfirmation, setActionConfirmation] = useState(false);
  const [showActionInfoModal, setShowActionInfoModal] = useState(false);

  const dropdownHandler = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    setShowActionModal(false);
    if (actionConfirmation) {
      if (actionType === "delete") {
        sendDeleteRequest(tripId);
      }
    }
    setActionConfirmation(false);
  }, [actionConfirmation]);

  useEffect(() => {
    if (deleteStatus === "completed" && deleteError === null) {
      setShowActionInfoModal(true);
    }
  }, [deleteStatus, deleteError]);

  //Hide the info modal and re-render the listing.
  const infoModalConfirmationHandler = () => {
    setShowActionInfoModal(false);
    if (actionType === "delete") {
      navigate(`/admin-32423QeCFer/user-details/${userId}`, { replace: true });
    }
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setIsDropdownOpen(false);
      }}
    >
      <div className="info" onClick={dropdownHandler}>
        <img src={info} alt="info" />
        <div className={`info-detail ${isDropdownOpen ? "show" : ""}`}>
          <ul className="list-style-none">
            <li
              onClick={() => {
                setActionType("delete");
                setShowActionModal(true);
              }}
            >
              <i className="fa fa-trash"></i>
              Delete Trip
            </li>
          </ul>
        </div>
      </div>
      {showActionModal && (
        <ModalWithState
          showModal={setShowActionModal}
          onConfirmModal={setActionConfirmation}
          message={
            actionType === "delete" &&
            "WARNING! Are you sure you would like to delete this trip?"
          }
        />
      )}
      {showActionInfoModal && (
        <ModalWithInfo
          showModal={infoModalConfirmationHandler}
          heading={"Success"}
          message={
            actionType === "delete" && "The trip is successfully deleted."
          }
        />
      )}
      {deleteError && <AdminApiErrors>{deleteError}</AdminApiErrors>}
    </OutsideClickHandler>
  );
};

export default AdminTripCardDropdown;
