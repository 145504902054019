import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './AdminSidebar.scss';

const AdminSidebar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className="admin-sidebar">
            <div className="hamburger-menu" onClick={toggleMenu}>
                &#9776;
            </div>
            <ul className={`sidebar-menu ${isMenuOpen ? 'open' : ''}`}>
                <li>
                    <Link to="/admin-32423QeCFer">Dashboard</Link>
                </li>
                <li>
                    <Link to="/admin-32423QeCFer/all-users">Users</Link>
                </li>
                <li>
                    <Link to="/admin-32423QeCFer/all-trips">Trips</Link>
                </li>
                <li>
                    <Link to="/admin-32423QeCFer/all-features">Features</Link>
                </li>
                <li>
                    <Link to="/admin-32423QeCFer/subscription-plans">Subscription Plans</Link>
                </li>
                <li>
                    <Link to="/admin-32423QeCFer/all-transactions">Transactions</Link>
                </li>
            </ul>
        </div>
    );
};

export default AdminSidebar;
