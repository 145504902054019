import React, {useState, useEffect} from "react";
import useInput from "../../hooks/use-input";
import useHttp from "../../hooks/use-http";
import validator from "validator";
import {updateUserSettings} from "../../lib/api";
import ApiErrors from "../../components/api-errors/ApiErrors";
import ModalWithInfo from "../../components/ui/ModalWithInfo";

const PersonalSettings = (props) => {
    const {sendRequest, status, error} = useHttp(updateUserSettings);
    const [showActionInfoModal, setShowActionInfoModal] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [showApiErrors, setShowApiErrors] = useState(false);

    const userData = props.userData;
    const planInfo = props.planInfo;

    const {
        value: enteredName,
        hasError: nameHasError,
        valueIsValid: nameIsValid,
        valueChangeHandler: nameChangeHandler,
        inputBlurHandler: nameBlurHandler,
    } = useInput((value) => value.trim().length > 2, userData.name);

    const {
        value: enteredEmail,
        hasError: emailHasError,
        valueIsValid: emailIsValid,
        valueChangeHandler: emailChangeHandler,
        inputBlurHandler: emailBlurHandler,
    } = useInput((value) => value.includes("@"), userData.email);

    const {
        value: enteredPhone,
        hasError: phoneHasError,
        valueIsValid: phoneIsValid,
        valueChangeHandler: phoneChangeHandler,
        inputBlurHandler: phoneBlurHandler,
    } = useInput(
        (value) => validator.isMobilePhone(value + "", "en-US"),
        userData.phone_number
    );

    const formIsValid = nameIsValid && emailIsValid && phoneIsValid;

    const formSubmitHandler = () => {
        setShowApiErrors(false);
        if (formIsValid) {
            const userData = {
                name: enteredName,
                email: enteredEmail,
                phone_number: enteredPhone,
            };
            sendRequest(userData);
        }
    };

    useEffect(() => {
        if (error) {
            setShowApiErrors(true);
        }
        if (status === "completed" && error === null) {
            setShowActionInfoModal(true);
        }
    }, [status, error]);

    const infoModalConfirmationHandler = () => {
        setShowActionInfoModal(false);
        setIsDisabled(true);
        props.refreshHandler();
    };

    return (
        <>
            <div className="col-12">
                <div className="profile-head-wrapper">
                    <h2 className="section-title">Personal Information</h2>
                    {isDisabled ? (
                        <>
                            <p>
                                <strong>Full Name: </strong>
                                {userData.name}
                            </p>
                            <p>
                                <strong>Email: </strong>
                                {userData.email}
                            </p>
                            <p>
                                <strong>Phone Number: </strong>
                                {userData.phone_number}
                            </p>
                        </>
                    ) : (
                        <div className="row">
                            <div className="col-6">
                                <ul className="list-style-none">
                                    <li>
                                        <label htmlFor="">Full Name</label>
                                        <input
                                            type="text"
                                            placeholder="Type here..."
                                            className="number-list"
                                            value={enteredName}
                                            onChange={nameChangeHandler}
                                            onBlur={nameBlurHandler}
                                        />

                                        {nameHasError && (
                                            <p className="error-msg">
                                                Please enter a valid name. (At least 3 characters)
                                            </p>
                                        )}
                                    </li>
                                    <li>
                                        <label htmlFor="">Email Address</label>
                                        <input
                                            type="email"
                                            placeholder="Type here..."
                                            className="number-list"
                                            value={enteredEmail}
                                            onChange={emailChangeHandler}
                                            onBlur={emailBlurHandler}
                                        />

                                        {emailHasError && (
                                            <p className="error-msg">Please enter a valid email.</p>
                                        )}
                                    </li>
                                    <li>
                                        <label htmlFor="">Phone Number</label>
                                        <input
                                            type="text"
                                            placeholder="Type here..."
                                            className="number-list"
                                            value={enteredPhone}
                                            onChange={phoneChangeHandler}
                                            onBlur={phoneBlurHandler}
                                        />

                                        {phoneHasError && (
                                            <p className="error-msg">
                                                Please enter a valid US Phone number.
                                            </p>
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    )}
                    <div className="flex">
                        {!isDisabled && (
                            <button
                                onClick={formSubmitHandler}
                                className={`signup-btn ${formIsValid ? "" : "disabled-link"} ${
                                    status === "pending" ? "disabled-link" : ""
                                }`}
                            >
                                Update
                            </button>
                        )}
                        <button
                            onClick={() => {
                                setIsDisabled(!isDisabled);
                                setShowApiErrors(false);
                            }}
                            className="signup-btn"
                        >
                            {isDisabled ? "Edit Personal Information" : "Cancel"}
                        </button>
                    </div>

                    {showActionInfoModal && (
                        <ModalWithInfo
                            heading="Success"
                            message="Your details are successfully updated!"
                            showModal={infoModalConfirmationHandler}
                        />
                    )}
                    {showApiErrors && <ApiErrors>{error}</ApiErrors>}
                </div>
            </div>
            <div className="col-12">
                <div className="profile-head-wrapper">
                    <h2 className="section-title">Subscription Information</h2>
                    <p>
                        <strong>Plan: </strong>
                        {planInfo?.planName}
                    </p>
                    <p>
                        <strong>Variation: </strong>
                        {planInfo?.variation}
                    </p>
                    <p>
                        <strong>Start Date: </strong>
                        {planInfo?.startDate}
                    </p>
                    <p>
                        <strong>End Date: </strong>
                        {planInfo?.endDate}
                    </p>
                </div>
            </div>
        </>
    );
};
export default PersonalSettings;
