import {useDispatch} from "react-redux";
import {authActions} from "../../store/auth-slice";
import {useNavigate} from "react-router-dom";
import React from 'react'
import {toast} from 'react-toastify';

const ApiErrors = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    if (typeof props.children === "string" && props.children === "Unauthenticated") {
        dispatch(authActions.logoutUser());
        navigate("/signin", {replace: true});
    }

    const errorData = JSON.parse(props.children);

    if (errorData.message && errorData.message === "Unauthenticated") {
        dispatch(authActions.logoutUser());
        navigate("/signin", {replace: true});
    }

    if (errorData.message === 'feature_error') {
        toast.error(errorData.errors['feature'][0] ?? '');
        return <></>;
    }

    let errorsArray = [];

    for (const error in errorData.errors) {
        errorsArray.push(
            <p className="error-msg" key={error}>
                {errorData.errors[error].toString()}
            </p>
        );
    }
    return <>{errorsArray}</>;
};

export default ApiErrors;
