import React, { useState } from "react";
import Pagination from "./Pagination";

const ServerPagination = ({ totalCount, getPage, pageSize = 10, setPage }) => {
    const [currentPage, setCurrentPage] = useState(setPage);

    return (
        <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={pageSize}
            onPageChange={(page) => {
                setCurrentPage(page);
                getPage(page);  // Fetch the correct page
            }}
        />
    );
};

export default ServerPagination;
