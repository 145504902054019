import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isUserLoggedIn: !!localStorage.getItem("user-token"),
  authToken: localStorage.getItem("user-token"),
  userId: localStorage.getItem("user-id"),
  userImage: localStorage.getItem("user-image"),
  userName: localStorage.getItem("user-name"),
  socialLoginWithoutPhone: localStorage.getItem("social-no-phone") === "true" ? true : false,
  userFeatures: localStorage.getItem("user-features") ? JSON.parse(localStorage.getItem("user-features")) : [],
  userSubscription: localStorage.getItem("user-subscription") ? JSON.parse(localStorage.getItem("user-subscription")) : 0,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateAuthToken(state, action) {
      localStorage.setItem("user-token", action.payload.token);
      localStorage.setItem("user-id", action.payload.user_id);
      localStorage.setItem("user-image", action.payload.image);
      localStorage.setItem("user-name", action.payload.name);
      if (action?.payload?.phone_number) {
        localStorage.setItem("social-no-phone", "true");
        state.socialLoginWithoutPhone = true;
      }
      if (action?.payload?.features) {
        localStorage.setItem("user-features", JSON.stringify(action.payload.features));
        state.userFeatures = action.payload.features;
      }
      if (action?.payload?.subscription_plan_id) {
        localStorage.setItem("user-subscription", JSON.stringify(action.payload.subscription_plan_id));
        state.userSubscription = action.payload.subscription_plan_id;
      }
      // const {token, user_id}= action.payload;
      // state = {
      //   ...state,
      //   userId: user_id,
      //   authToken: token
      // }
      state.authToken = action.payload.token;
      state.userId = action.payload.user_id;
      state.isUserLoggedIn = true;
      state.userImage = action.payload.image;
      state.userName = action.payload.name;
    },
    updateSocialLoginWithoutPhone(state, action) {
      localStorage.setItem("social-no-phone", action.payload);
      state.socialLoginWithoutPhone = action.payload;
    },
    updateUserProfileImage(state, action) {
      state.userImage = action.payload;
      localStorage.setItem("user-image", action.payload);
    },
    logoutUser(state) {
      localStorage.removeItem("user-token");
      localStorage.removeItem("user-id");
      localStorage.removeItem("user-image");
      localStorage.removeItem("user-name");
      localStorage.removeItem("social-no-phone");
      localStorage.removeItem("user-features");
      localStorage.removeItem("user-subscription");
      state.authToken = null;
      state.isUserLoggedIn = false;
      state.userId = null;
      state.userImage = null;
      state.userName = null;
      state.socialLoginWithoutPhone = null;
      state.userFeatures = [];
      state.userSubscription = 0;
    },
    updateFeatures(state, action) {
      if (action.payload.features) {
        localStorage.setItem("user-features", JSON.stringify(action.payload.features));
        state.userFeatures = action.payload.features;
      }
    },
    updateSubscription(state, action) {
      if (action.payload?.subscription?.subscription_plan_id) {
        localStorage.setItem("user-subscription", JSON.stringify(action.payload?.subscription?.subscription_plan_id));
        state.userSubscription = action.payload?.subscription?.subscription_plan_id;
      }
    }
  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
