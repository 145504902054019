import React, { useState } from "react";
import useInput from "../../../hooks/use-input";
import validator from "validator";
import Calendar from "react-calendar";
import OutsideClickHandler from "react-outside-click-handler";
import {
  getDateToStringWithoutTime,
  getDateWithoutTime,
} from "../../../lib/api";

const AdminSubscriptionPlansSearch = ({ submitSearch, resetSearch }) => {
  const [selectedStatus, setSelectedStatus] = useState("");
  const [date, setDate] = useState([new Date()]);
  const [endDate, setEndDate] = useState([new Date()]);
  const [deActiveDate, setDeActiveDate] = useState([new Date()]);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isEndDateCalendarOpen, setIsEndDateCalendarOpen] = useState(false);
  const [isDeActiveDateCalendarOpen, setIsDeActiveDateCalendarOpen] = useState(
    false
  );
  const [isDateFilterActive, setIsDateFilterActive] = useState(false);
  const [isEndDateFilterActive, setIsEndDateFilterActive] = useState(false);
  const [isDeActiveDateFilterActive, setIsDeActiveDateFilterActive] = useState(
    false
  );
  const {
    value: enteredName,
    valueChangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
    resetValue: resetName,
  } = useInput((value) => value.trim().length > 0 && value.trim().length < 99);

  const {
    value: enteredEmail,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    resetValue: resetEmail,
  } = useInput((value) => value.trim().includes("@"));
  const formatDateRange = (dates) => {
    if (dates[1]) {
        if (
            getDateWithoutTime(dates[0]).getTime() ===
            getDateWithoutTime(dates[1]).getTime()
          ) {
            return getDateToStringWithoutTime(dates[0]);
          }else{

            return (
                getDateToStringWithoutTime(dates[0]) +
                " - " +
                getDateToStringWithoutTime(dates[1])
              );

          }

      }
      return getDateToStringWithoutTime(dates[0]);

  }

  // Display functions using the helper function
const displayDate = () => formatDateRange(date, setDate);
const displayEndDate = () => formatDateRange(endDate, setEndDate);
const displayDeActiveDate = () => formatDateRange(deActiveDate, setDeActiveDate);

  const {
    value: enteredPhone,
    hasError: phoneHasError,
    valueChangeHandler: phoneChangeHandler,
    inputBlurHandler: phoneBlurHandler,
    resetValue: resetPhone,
  } = useInput((value) => validator.isMobilePhone(value + "", "en-US"));

  const toggleCalendar = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };
  const toggleEndDateCalendar = () => {
    setIsEndDateCalendarOpen(!isEndDateCalendarOpen);
  };
  const toggleDeActiveCalendar = () => {
    setIsDeActiveDateCalendarOpen(!isDeActiveDateCalendarOpen);
  };

  const formSubmitHandler = (event) => {
    event.preventDefault();



    let searchParams =
      `${
        isDateFilterActive && date[1]
          ? `&start_date=${getDateToStringWithoutTime(
              date[0]
            )}&end_date=${getDateToStringWithoutTime(date[1])}`
          : ""
      }` +
      `${
        isEndDateFilterActive && endDate[1]
          ? `&end_start_date=${getDateToStringWithoutTime(
              endDate[0]
            )}&end_end_date=${getDateToStringWithoutTime(endDate[1])}`
          : ""
      }` +
      `${
        isDeActiveDateFilterActive && deActiveDate[1]
          ? `&deActive_start_date=${getDateToStringWithoutTime(
              deActiveDate[0]
            )}&deActive_end_date=${getDateToStringWithoutTime(deActiveDate[1])}`
          : ""
      }` +
      `${enteredName ? `&name=${enteredName}` : ""}` +
      `${enteredEmail ? `&email=${enteredEmail}` : ""}` +
      `${enteredPhone ? `&phone=${enteredPhone}` : ""}` +
      `${selectedStatus ? `&status=${selectedStatus}` : ""}`;

      if (enteredPhone !== "" && phoneHasError) {
        return null;
      }

     submitSearch(searchParams);
  };

  const dateChangeHandler = (selectedDate) => {
    setDate(selectedDate);
    setIsCalendarOpen(false);
  };

  const endDateChangeHandler = (selectedDate) => {

    setIsEndDateCalendarOpen(false);
    setEndDate(selectedDate);

  };

  const deActiveDateChangeHandler = (selectedDate) => {
    setDeActiveDate(selectedDate);
    setIsDeActiveDateCalendarOpen(false);
  };

  return (
    <div className="admin-search-wrapper">
      <h2 className="search-title">Search</h2>
      <form onSubmit={formSubmitHandler}>
        <ul className="list-style-none flex flex-wrap items-start">
          <li>
            <label>Name</label>
            <input
              type="text"
              className="number-list"
              placeholder="Name"
              value={enteredName}
              onChange={nameChangeHandler}
              onBlur={nameBlurHandler}
            />
          </li>

          <li>
            <label>Email</label>
            <input
              type="email"
              className="number-list"
              placeholder="Email"
              value={enteredEmail}
              onChange={emailChangeHandler}
              onBlur={emailBlurHandler}
            />
            {enteredEmail !== "" && emailHasError && (
              <p className="error-msg">Please enter a valid email.</p>
            )}
          </li>

          <li>
            <label>Phone</label>
            <input
              type="text"
              className="number-list"
              placeholder="Phone"
              value={enteredPhone}
              onChange={phoneChangeHandler}
              onBlur={phoneBlurHandler}
            />
            {enteredPhone !== "" && phoneHasError && (
              <p className="error-msg">Please enter a valid phone.</p>
            )}
          </li>

          <OutsideClickHandler
            onOutsideClick={() => {
              setIsCalendarOpen(false);
            }}
          >
            <li>
              <input
                type="checkbox"
                checked={isDateFilterActive}
                onChange={() => {
                  setIsDateFilterActive(!isDateFilterActive);
                }}
              />
              Start Date
              <button
                type="button"
                className={`number-list ${!isDateFilterActive &&
                  "disabled-link"} ${isCalendarOpen ? "expanded" : ""}`}
                onClick={toggleCalendar}
              >
                <strong>Date / Date Range: </strong>
                <span>{displayDate()}</span>
              </button>
              <div className={`calender-wrap ${isCalendarOpen ? "show" : ""}`}>
                <Calendar
                  onChange={dateChangeHandler}
                  closeCalendar={true}
                  selectRange={true}
                />
              </div>
            </li>
          </OutsideClickHandler>

          <OutsideClickHandler
            onOutsideClick={() => {
              setIsEndDateCalendarOpen(false);
            }}
          >
            <li>
              <input
                type="checkbox"
                checked={isEndDateFilterActive}
                onChange={() => {
                  setIsEndDateFilterActive(!isEndDateFilterActive);
                }}
              />
              End Date
              <button
                type="button"
                className={`number-list ${!isEndDateFilterActive &&
                  "disabled-link"} ${isEndDateCalendarOpen ? "expanded" : ""}`}
                onClick={toggleEndDateCalendar}
              >
                <strong>Date / Date Range: </strong>
                <span>{displayEndDate()}</span>
              </button>
              <div
                className={`calender-wrap ${
                  isEndDateCalendarOpen ? "show" : ""
                }`}
              >
                <Calendar
                  onChange={endDateChangeHandler}
                  closeCalendar={true}
                  selectRange={true}
                />
              </div>
            </li>
          </OutsideClickHandler>

          <OutsideClickHandler
            onOutsideClick={() => {
              setIsDeActiveDateCalendarOpen(false);
            }}
          >
            <li>
              <input
                type="checkbox"
                checked={isDeActiveDateFilterActive}
                onChange={() => {
                  setIsDeActiveDateFilterActive(!isDeActiveDateFilterActive);
                }}
              />
              DeActivate Date
              <button
                type="button"
                className={`number-list ${!isDeActiveDateFilterActive &&
                  "disabled-link"} ${
                  isDeActiveDateCalendarOpen ? "expanded" : ""
                }`}
                onClick={toggleDeActiveCalendar}
              >
                <strong>Date / Date Range: </strong>
                <span>{displayDeActiveDate()}</span>
              </button>
              <div
                className={`calender-wrap ${
                  isDeActiveDateCalendarOpen ? "show" : ""
                }`}
              >
                <Calendar
                  onChange={deActiveDateChangeHandler}
                  closeCalendar={true}
                  selectRange={true}
                />
              </div>
            </li>
          </OutsideClickHandler>

          <li>
            <label>Plan Status</label>
            <select
              onChange={(event) => {
                setSelectedStatus(event.target.value);
              }}
              className="number-list"
              value={selectedStatus}
            >
              <option value="">All</option>
              <option value="1">Active</option>
              <option value="0">Inactive</option>
            </select>
          </li>
          <li>
            <label style={{ opacity: "0" }}>Search</label>
            <button type="submit" className="signup-btn submit-btn">
              Search
            </button>
          </li>

          <li>
            <label style={{ opacity: "0" }}>Reset</label>
            <button
              onClick={(event) => {
                event.preventDefault();
                resetSearch();
                resetName();
                resetEmail();
                resetPhone();
                setIsDateFilterActive(false);
                setDate([new Date()]);
                setIsEndDateFilterActive(false);
                setEndDate([new Date()]);
                setIsDeActiveDateFilterActive(false);
                setDeActiveDate([new Date()]);
              }}
              className="signup-btn submit-btn"
            >
              Reset
            </button>
          </li>
        </ul>
      </form>
    </div>
  );
};

export default AdminSubscriptionPlansSearch;
