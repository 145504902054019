import React from "react";
import useInput from "../../../hooks/use-input";
import validator from "validator";

const AdminUsersSearch = (props) => {
    const {
        value: enteredName,
        valueIsValid: nameIsValid,
        valueChangeHandler: nameChangeHandler,
        inputBlurHandler: nameBlurHandler,
        resetValue: resetName,
    } = useInput((value) => value.trim().length > 0 && value.trim().length < 99);

    const {
        value: enteredEmail,
        hasError: emailHasError,
        valueIsValid: emailIsValid,
        valueChangeHandler: emailChangeHandler,
        inputBlurHandler: emailBlurHandler,
        resetValue: resetEmail,
    } = useInput((value) => value.trim().includes("@"));

    const {
        value: enteredPhone,
        hasError: phoneHasError,
        valueIsValid: phoneIsValid,
        valueChangeHandler: phoneChangeHandler,
        inputBlurHandler: phoneBlurHandler,
        resetValue: resetPhone,
    } = useInput((value) => validator.isMobilePhone(value + "", "en-US"));

    const formIsInvalid =
        (enteredEmail !== "" && emailHasError) ||
        (enteredPhone !== "" && phoneHasError) ||
        (!nameIsValid && !emailIsValid && !phoneIsValid);

    const formSubmitHandler = (event) => {
        event.preventDefault();
        if (!formIsInvalid) {
            const searchData = {
                name: enteredName,
                email: enteredEmail,
                phone: enteredPhone,
            };
            props.submitSearch(searchData)
        }
    };

    return (
        <div className="admin-search-wrapper">
            <h2 className="search-title">Search</h2>
            <form onSubmit={formSubmitHandler}>
                <ul className="list-style-none flex flex-wrap items-start">
                    <li>
                        <input
                            type="text"
                            className="number-list"
                            placeholder="Name"
                            value={enteredName}
                            onChange={nameChangeHandler}
                            onBlur={nameBlurHandler}
                        />
                    </li>
                    <li>
                        <input
                            type="email"
                            className="number-list"
                            placeholder="Email"
                            value={enteredEmail}
                            onChange={emailChangeHandler}
                            onBlur={emailBlurHandler}
                        />
                        {enteredEmail !== "" && emailHasError && (
                            <p className="error-msg">Please enter a valid email.</p>
                        )}
                    </li>
                    <li>
                        <input
                            type="text"
                            className="number-list"
                            placeholder="Phone"
                            value={enteredPhone}
                            onChange={phoneChangeHandler}
                            onBlur={phoneBlurHandler}
                        />
                        {enteredPhone !== "" && phoneHasError && (
                            <p className="error-msg">Please enter a valid phone.</p>
                        )}
                    </li>
                    <li>
                        <button
                            type="submit"
                            className={`signup-btn submit-btn ${
                                formIsInvalid ? "disabled-link" : ""
                            }`}
                        >
                            Search
                        </button>
                    </li>
                    <li>
                        <button
                            onClick={(event) => {
                                event.preventDefault();
                                resetName();
                                resetEmail();
                                resetPhone();
                                props.resetSearch();
                            }}
                            className={"signup-btn submit-btn"}
                        >
                            Reset
                        </button>
                    </li>
                </ul>
            </form>
        </div>
    );
};

export default AdminUsersSearch;
