import React from "react";
import useInput from "../../hooks/use-input";

const SearchGear = ({submitSearch, resetSearch}) => {
    const {
        value: name,
        valueChangeHandler: nameChangeHandler,
        inputBlurHandler: nameBlurHandler,
        resetValue: resetName,
    } = useInput((value) => value.trim().length > 0 && value.trim().length < 99);

    const formIsInvalid = false;

    const formSubmitHandler = (event) => {
        event.preventDefault();
        if (!formIsInvalid) {
            submitSearch({
                name
            });
        }
    };

    return (
        <div className="admin-search-wrapper">
            <h2 className="search-title">Search</h2>
            <form onSubmit={formSubmitHandler}>
                <ul className="list-style-none flex flex-wrap items-start">
                    <li>
                        <input
                            type="text"
                            className="number-list"
                            placeholder="Name"
                            value={name}
                            onChange={nameChangeHandler}
                            onBlur={nameBlurHandler}
                        />
                    </li>
                    <li>
                        <button type="submit" className='signup-btn submit-btn'>
                            Search
                        </button>
                    </li>

                    <li>
                        <button
                            onClick={(event) => {
                                event.preventDefault();
                                resetSearch();
                                resetName();
                            }}
                            className={"signup-btn submit-btn"}
                        >
                            Reset
                        </button>
                    </li>
                </ul>
            </form>
        </div>
    );
};

export default SearchGear;

