import React, {useState} from "react";
import useInput from "../../../hooks/use-input";

const AdminFeatureSearch = ({submitSearch, resetSearch}) => {
    const [selectedFeatureStatus, setSelectedFeatureStatus] = useState("");
    const [selectedFeatureType, setSelectedFeatureType] = useState("");
    const {
        value: featureName,
        valueChangeHandler: featureNameChangeHandler,
        inputBlurHandler: featureNameBlurHandler,
        resetValue: resetFeatureName,
    } = useInput((value) => value.trim().length > 0 && value.trim().length < 99);

    const formSubmitHandler = (event) => {
        event.preventDefault();
        submitSearch({featureName, selectedFeatureStatus, selectedFeatureType})
    };

    return (
        <div className="admin-search-wrapper">
            <h2 className="search-title">Search</h2>
            <form onSubmit={formSubmitHandler}>
                <ul className="list-style-none flex flex-wrap items-start">
                    <li>
                        <label>Name</label>
                        <input type="text"
                               className="number-list"
                               placeholder="Feature Name"
                               value={featureName}
                               onChange={featureNameChangeHandler}
                               onBlur={featureNameBlurHandler}
                        />
                    </li>
                    <li>
                        <label>Status</label>
                        <select onChange={(event) => {
                            setSelectedFeatureStatus(event.target.value);
                        }}
                                className="number-list"
                                value={selectedFeatureStatus}
                        >
                            <option value="">All</option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                        </select>
                    </li>
                    <li>
                        <label>Type</label>
                        <select onChange={(event) => {
                            setSelectedFeatureType(event.target.value);
                        }}
                                className="number-list"
                                value={selectedFeatureType}
                        >
                            <option value="">All</option>
                            <option value="free">Free</option>
                            <option value="premium">Premium</option>
                        </select>
                    </li>
                    <li>
                        <button type="submit" className='signup-btn submit-btn'>
                            Search
                        </button>
                    </li>

                    <li>
                        <button onClick={(event) => {
                            event.preventDefault();
                            resetSearch();
                            resetFeatureName();
                            setSelectedFeatureStatus("");
                            setSelectedFeatureType("");
                        }}
                                className="signup-btn submit-btn"
                        >
                            Reset
                        </button>
                    </li>
                </ul>
            </form>
        </div>
    );
};

export default AdminFeatureSearch;
