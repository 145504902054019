import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {SpinnerModal} from "../../components/ui/SpinnerModal";
import {acceptInviteApi} from "../../lib/api";
import useHttp from "../../hooks/use-http";
import ApiErrors from "../../components/api-errors/ApiErrors";

export const InviteSuccess = () => {
    const isLoggedIn = useSelector((state) => state.auth.isUserLoggedIn);
    const navigate = useNavigate();

    const {sendRequest, status, error, data} = useHttp(acceptInviteApi);
    const [responseMessage, setResponseMessage] = useState("");
    const [apiErrorStatus, setapiErrorStatus] = useState(false);
    const {token} = useParams();

    useEffect(() => {
        if (token) {
            if (isLoggedIn) {
                handleInviteAcceptance(token);
            }
        } else {
            setapiErrorStatus(false);
            setResponseMessage("No invitation token found.");
        }
    }, [isLoggedIn]);

    const handleInviteAcceptance = (token) => {
        sendRequest({token});
    };

    useEffect(() => {
        if (status === "completed" && error === null) {
            const tripId = data?.trip_id;
            setResponseMessage("Invitation accepted successfully!");
            setapiErrorStatus(false);
            if (tripId) {
                setTimeout(() => {
                    navigate(`/my-account/trip-details/${tripId}`);
                }, 3000);
            }
        } else if (status === "completed" && error) {
            setapiErrorStatus(true);
            setResponseMessage(<ApiErrors>{error}</ApiErrors>);
        }
    }, [status, data, error]);

    const handleLoginRedirect = () => {
        navigate("/signin");
    };

    const handleRegisterRedirect = () => {
        navigate("/signup");
    };

    return (
        <div className="main-trail-head invite-success flex column-direction flex-wrap w-100 content-justify-start">
            <div className="container column-direction">
                {!isLoggedIn && (
                    <div>
                        <h1><i className="fa fa-window-close" aria-hidden="true"></i> Invitation Pending</h1>
                        <p>Please log in or sign up to accept your invitation, then revisit or click the invite link to proceed.</p>
                        <button onClick={handleLoginRedirect}>Log In</button>
                        <button onClick={handleRegisterRedirect}>Register</button>
                    </div>
                )}

                {isLoggedIn && status === "pending" && <SpinnerModal />}

                {responseMessage && (
                    <div>
                        {apiErrorStatus ? (
                            <>
                                <h1><i className="fa fa-exclamation-circle" aria-hidden="true"></i> Invitation Rejected</h1>
                                {responseMessage}
                            </>

                        ) : (
                            <>
                                <h1><i className="fa fa-info-circle" aria-hidden="true"></i> Invitation Accepted</h1>
                                {responseMessage}
                                <p>Redirecting to your trip details...</p>
                            </>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
