import React from "react";
import ServerPagination from "../../components/ui/ServerPagination";
import GoogleMapReact from "google-map-react";

const MapPin = () => <div className="map-pin-text"/>;
import {Link} from "react-router-dom";
import {getDateToStringWithoutTime} from "../../lib/api";
import './community-trips.scss'

const List = ({data, totalCount, currentPage, pagination}) => {
    return (
        <>
            <ul className="list-style-none cards-wrapper flex flex-wrap">
                {data.length === 0 ? (
                    <li className="no-trip">No Trips Found</li>
                ) : (
                    data.map((singleTripData) => (
                        <li key={singleTripData.id}>
                            <div className="cards-wrap">
                                <div className="card-map">
                                    <GoogleMapReact
                                        bootstrapURLKeys={{
                                            key: process.env.REACT_APP_GOOGLE_API_KEY,
                                        }}
                                        center={{
                                            lat: +singleTripData.latitude,
                                            lng: +singleTripData.longitude,
                                        }}
                                        defaultZoom={11}
                                    >
                                        <MapPin
                                            lat={+singleTripData.latitude}
                                            lng={+singleTripData.longitude}
                                            pinText="Map"
                                        />
                                    </GoogleMapReact>
                                </div>
                                <section>
                                    <Link
                                        to={`/community-trips/detail/${singleTripData.id}`}>
                                        <h2>{singleTripData.activity}</h2>
                                        <p><i className="fa fa-map-marker"></i>{singleTripData.location}</p>
                                        <p>
                                            <i className="fa fa-calendar-o"></i>
                                            {getDateToStringWithoutTime(
                                                new Date(singleTripData.start_date)
                                            )}{" "}
                                            {singleTripData.end_date
                                                ? `- ${getDateToStringWithoutTime(
                                                    new Date(singleTripData.end_date)
                                                )}`
                                                : ""}
                                        </p>
                                        <p>
                                            {singleTripData.public === "true" ? (
                                                <span><i className="fa fa-eye"></i>Public</span>
                                            ) : (
                                                <span><i className="fa fa-eye-slash"></i>Private</span>
                                            )}
                                            {singleTripData.created_at && (
                                                <span className="created-date">
                                                    <i className="fa fa-calendar"></i>
                                                    Created:{" "}
                                                    {getDateToStringWithoutTime(
                                                        new Date(singleTripData.created_at)
                                                    )}
                                                </span>
                                            )}
                                        </p>
                                        <div className="flex items-center">
                                            <span className={`status status-${singleTripData.status}`}>
                                                {singleTripData.status.charAt(0).toUpperCase() + singleTripData.status.slice(1)}
                                            </span>
                                        </div>
                                    </Link>
                                </section>
                                <footer className="card-footer">
                                    <Link  to={`/community-trips/createTrip/${singleTripData.id}/${singleTripData.latitude}/${singleTripData.longitude}/${singleTripData.location}/${singleTripData.start_date}/${singleTripData.end_date}/${singleTripData.activity}`} className="start-plan-btn">
                                        Start From this Plan
                                    </Link>
                                </footer>
                            </div>
                        </li>
                    ))
                )}
            </ul>

            {data.length > 0 && (
                <ServerPagination allData={data} getPage={(page) => pagination(page)} totalCount={totalCount}
                                  setPage={currentPage}/>
            )}
        </>
    );
};

export default List;
