import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useHttp from "../../../hooks/use-http";
import {
  getUserData,
  adminBlockUser,
  adminUnblockUser,
} from "../../../lib/admin-api";
import { SpinnerModal } from "../../../components/ui/SpinnerModal";
import AdminTripCard from "../admin-trips/AdminTripCard";
import { getDateToStringWithoutTime } from "../../../lib/api";
import "../../user-dashboard/profile.scss";
import ModalWithInfo from "../../../components/ui/ModalWithInfo";
import ModalWithState from "../../../components/ui/ModalWithState";
import AdminApiErrors from "../../../components/api-errors/AdminApiErrors";
import AddPagination from "../../../components/ui/AddPagination";
import {getInitials} from "../../../utilities/NameInitials";

export const AdminViewUserDetails = () => {
  const params = useParams();
  const userId = params.id;
  const [paginatedUserTrips, setPaginatedUserTrips] = useState();
  const [paginatedInvitedTrips, setPaginatedInvitedTrips] = useState();
  const [showPagination, setShowPagination] = useState(true);

  //For Block/Unblock Request
  const [actionType, setActionType] = useState("");
  const [showActionModal, setShowActionModal] = useState(false);
  const [actionConfirmation, setActionConfirmation] = useState(false);
  const [showActionInfoModal, setShowActionInfoModal] = useState(false);
  const {
    sendRequest: sendBlockRequest,
    status: blockRequestStatus,
    error: blockRequestError,
  } = useHttp(adminBlockUser);
  const {
    sendRequest: sendUnblockRequest,
    status: unblockRequestStatus,
    error: unblockRequestError,
  } = useHttp(adminUnblockUser);

  //For fetching user data
  const { sendRequest, status, error, data } = useHttp(getUserData);
  //When a trip is deleted in dropdown component, we need to refetch and re-render the cards.
  const [refreshComponent, setRefreshComponent] = useState(false);
  const refreshHandler = () => {
    setRefreshComponent(!refreshComponent);
  };

  //For Pagination - refer to comment in AdminUsersList.js
  useEffect(() => {
    setShowPagination(false);
    setTimeout(() => {
      if (data) {
        setPaginatedUserTrips(data.all_trips);
        setPaginatedInvitedTrips(data.invited_trips);
      }
      setShowPagination(true);
    }, 50);
  }, [data]);

  //For fetching user's data.
  useEffect(() => {
    sendRequest(userId);
  }, [sendRequest, refreshComponent]);

  //For block/unblock user
  useEffect(() => {
    setShowActionModal(false);
    if (actionConfirmation) {
      if (actionType === "block") {
        sendBlockRequest(userId);
      } else if (actionType === "unblock") {
        sendUnblockRequest(userId);
      }
    }
    setActionConfirmation(false);
  }, [actionConfirmation]);

  //In case of success
  useEffect(() => {
    if (blockRequestStatus === "completed" && blockRequestError === null) {
      setShowActionInfoModal(true);
    }
    if (unblockRequestStatus === "completed" && unblockRequestError === null) {
      setShowActionInfoModal(true);
    }
  }, [
    blockRequestStatus,
    blockRequestError,
    unblockRequestStatus,
    unblockRequestError,
  ]);

  const infoModalConfirmationHandler = () => {
    setShowActionInfoModal(false);
    if (actionType === "block") {
      // console.log("Block the user");
    } else if (actionType === "unblock") {
      // console.log("Unblock the user");
    }
    refreshHandler();
  };

  return (
    <>
      {!error ? (
        <>
          {status === "pending" || status === null ? (
            <SpinnerModal />
          ) : (
            <>
              {data && (
                <>
                  <div className="flex flex-wrap w-100 user-detail-wrapper content-justify-center column-direction">
                    <div className="container">
                      <ul className="list-style-none flex column-direction w-100">
                        <li>
                          <div className="flex w-100 items-start content-wrapper column-direction">
                            <div className="flex w-100 items-center">
                              { data.user.image ? (
                                  <img
                                      src={data.user.image}
                                      alt={data.user.name}
                                  />
                              ) : (
                                  <div
                                      className="initials-avatar"
                                      style={{
                                        width: "175px",
                                        height: "130px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        backgroundColor: "#ccc",
                                        color: "#fff",
                                        fontSize: "32px",
                                        marginRight: "20px"
                                      }}
                                  >
                                    {getInitials(data.user.name)}
                                  </div>
                              )}
                              <section>
                                <h2>{data.user.name}</h2>
                                <ul className="list-style-none flex flex-wrap">
                                  <li>
                                    <span>
                                      <strong>Email:</strong>
                                      {data.user.email}
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      <strong>Phone:</strong>
                                      {data.user.phone_number}
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      <strong>Member Since:</strong>
                                      {getDateToStringWithoutTime(
                                        new Date(data.user.email_verified_at)
                                      )}
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      <strong>Total Trips:</strong>
                                      {data.user.my_trip_count}
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      <strong>Invited to Trips:</strong>
                                      {data.user.invites_count}
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      <strong>User's Account Status:</strong>
                                      {data.user.status === "active"
                                        ? "Active"
                                        : "Blocked"}
                                    </span>
                                  </li>
                                </ul>
                              </section>
                              <div className="button-wrap flex ml-auto column-direction">
                                <button
                                  className={`signup-btn submit-btn ${
                                    blockRequestStatus === "pending" ||
                                    unblockRequestStatus === "pending"
                                      ? "disabled-link"
                                      : ""
                                  }`}
                                  onClick={() => {
                                    if (data.user.status === "active") {
                                      setActionType("block");
                                    } else {
                                      setActionType("unblock");
                                    }
                                    setShowActionModal(true);
                                  }}
                                >
                                  {data.user.status === "active"
                                    ? "Block User"
                                    : "Unblock User"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="main-profile-list admin-list-p flex flex-wrap w-100 content-justify-center">
                    <div className="container">
                      <h1 className="trail-title">{`${data.user.name}'s Trips`}</h1>
                      <ul className="list-style-none cards-wrapper flex flex-wrap">
                        {paginatedUserTrips && paginatedUserTrips.length > 0 ? (
                          paginatedUserTrips.map((tripData) => (
                            <AdminTripCard
                              key={tripData.id}
                              singleTripData={tripData}
                              refreshHandler={refreshHandler}
                            />
                          ))
                        ) : (
                          <li className="no-trip">No Trips Found</li>
                        )}
                      </ul>
                      {showPagination && (
                        <AddPagination
                          allData={data.all_trips}
                          setPaginatedData={setPaginatedUserTrips}
                        />
                      )}
                      <div className="clearfix"></div>
                      <h1 className="trail-title">Invited Trips</h1>
                      <ul className="list-style-none cards-wrapper flex flex-wrap">
                        {paginatedInvitedTrips &&
                        paginatedInvitedTrips.length > 0 ? (
                          paginatedInvitedTrips.map((tripData) => (
                            <AdminTripCard
                              key={tripData.id}
                              singleTripData={tripData}
                              refreshHandler={refreshHandler}
                            />
                          ))
                        ) : (
                          <li className="no-trip">No Trips Found</li>
                        )}
                      </ul>
                      {showPagination && (
                        <AddPagination
                          allData={data.invited_trips}
                          setPaginatedData={setPaginatedInvitedTrips}
                        />
                      )}
                    </div>
                  </div>
                  {showActionModal && (
                    <ModalWithState
                      showModal={setShowActionModal}
                      onConfirmModal={setActionConfirmation}
                      message={
                        (actionType === "block" &&
                          "WARNING! Are you sure you would like to BLOCK this user?") ||
                        (actionType === "unblock" &&
                          "Are you sure you would like to unblock this user?")
                      }
                    />
                  )}
                  {showActionInfoModal && (
                    <ModalWithInfo
                      showModal={infoModalConfirmationHandler}
                      heading={"Success"}
                      message={
                        (actionType === "block" &&
                          "The user is BLOCKED from the website. They cannot login to the website now.") ||
                        (actionType === "unblock" &&
                          "The user is UNBLOCKED successfully.")
                      }
                    />
                  )}
                </>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <AdminApiErrors>{error}</AdminApiErrors>
          <p className="no-trip">
            An error occured while fetching data from Backend. Please try again
            later.
          </p>
        </>
      )}
      {status === "completed" && error && (
        <p className="no-trip">
          Sorry! There is some error. Please contact site administrator.
        </p>
      )}
    </>
  );
};
