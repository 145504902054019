import "./splashPage.scss";
import { Link } from "react-router-dom";
import React from 'react'

export const SplashPage = () => {
  return (
    <React.Fragment>
    <div className="cal-to-action flex flex-wrap column-direction content-justify-center">
      <div className="container">
        <div className="home-rectangular">
          <h1>
            Adventure planning, <strong>Simplified.</strong>
          </h1>
          <Link className="signup-btn" to="/trip-plan">
            Let’s Plan Your Adventure!
          </Link>
        </div>
      </div>
    </div>
      {/* <Footer /> */}
    </React.Fragment>
  );
};
