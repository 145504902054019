import React, { useEffect, useState } from "react";
import {
  AdminSubscribedUsersListFetch,
} from "../../../lib/admin-api";
import { SpinnerModal } from "../../../components/ui/SpinnerModal";
import useHttp from "../../../hooks/use-http";
import {AdminSubscribedUsersList} from "./AdminSubscribedUsersList";
import AdminSubscribedUsersSearch from "./AdminSubscribedUsersSearch";
import AdminApiErrors from "../../../components/api-errors/AdminApiErrors";
import { useParams } from "react-router-dom";

export const AdminSubscribedUsers = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams, setSearchParams] = useState();
  const { id } = useParams();

  const { sendRequest, status, error, data } = useHttp(
    AdminSubscribedUsersListFetch
  );

  useEffect(() => {

    let query = `?plan_id=${id.trim()}`;
    query +=`&page=${currentPage}`;
    searchParams ?  query +=    searchParams : ``;
    console.log("🚀 ~ useEffect ~ searchParams:", searchParams)
    sendRequest(query);
  }, [sendRequest, currentPage, searchParams]); // Add searchParams to dependency array

  const submitSearch = (data) => {
    setSearchParams(data);
    setCurrentPage(1);
  };

  // Reset search and current page
  const resetSearch = () => {

   setSearchParams(); // Reset search params
    setCurrentPage(1); // Reset page to 1
  };

  const pagination = (page) => {
    setCurrentPage(page); // Set the current page in state
  };

  return (
    <>
      {!error ? (
        <div className="admin-panel-wrap admin-all-trips flex flex-wrap w-100 content-justify-center">
          <div className="container">
            <h1>Subscribed Users</h1>
            <br />
             <AdminSubscribedUsersSearch submitSearch={(data) => submitSearch(data)} resetSearch={resetSearch}/>
            {status === "pending" || status === null ? (
              <SpinnerModal />
            ) : (
              <>
                <h3>List</h3>
                <div className="table-responsive">
                  { <AdminSubscribedUsersList
                                        data={data?.data}
                                        pagination={(page) => pagination(page)}
                                        totalCount={data?.total}
                                        currentPage={currentPage}
                                    />  }
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="main-profile-detail flex flex-wrap column-direction w-100">
          <AdminApiErrors>{error}</AdminApiErrors>
          <p className="no-trip">
            An error occurred while fetching data from Backend. Please try again
            later.
          </p>
        </div>
      )}
    </>
  );
};
