import React, {useEffect, useState} from "react";
import {
    adminSubscriptionPlans,
    adminSubscriptionPlanToggle,
    adminSubscriptionPlanDefaultToggle
} from "../../../lib/admin-api";
import {SpinnerModal} from "../../../components/ui/SpinnerModal";
import useHttp from "../../../hooks/use-http";
import {AdminSubscriptionPlansList} from "./AdminSubscriptionPlansList";
import AdminSubscriptionPlansSearch from "./AdminSubscriptionPlansSearch";
import AdminApiErrors from "../../../components/api-errors/AdminApiErrors";
import {useNavigate} from "react-router-dom";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const AdminSubscriptionPlans = () => {

    const [currentPage, setCurrentPage] = useState(1);  // Store the current page
    const [searchParams, setSearchParams] = useState({
        planName: "",
        selectedStatus: "",
        selectedDefault: "",
        selectedPublish: ""
    });  // Store search params

    const navigate = useNavigate();
    const {sendRequest, status, error, data} = useHttp(adminSubscriptionPlans);
    const {
        sendRequest: planStatusToggleReq,
        status: planStatusToggleStatus,
        error: planStatusToggleError,
        data: planStatusToggleData
    } = useHttp(adminSubscriptionPlanToggle);

    const {
        sendRequest: planDefaultToggleReq,
        status: planDefaultToggleStatus,
        error: planDefaultToggleError,
        data: planDefaultToggleData
    } = useHttp(adminSubscriptionPlanDefaultToggle);

    useEffect(() => {
        let query = `?page=${currentPage}`;
        if (searchParams.planName) {
            query += `&name=${encodeURIComponent(searchParams.planName)}`;
        }
        if (searchParams.selectedStatus) {
            query += `&is_active=${searchParams.selectedStatus}`;
        }
        if (searchParams.selectedDefault) {
            query += `&is_default=${searchParams.selectedDefault}`;
        }
        if (searchParams.selectedPublish) {
            query += `&is_publish=${searchParams.selectedPublish}`;
        }
        sendRequest(query);
    }, [sendRequest, currentPage, searchParams]);  // Add searchParams to dependency array

    const submitSearch = (data) => {
        setSearchParams({
            planName: data.planName,
            selectedStatus: data.selectedStatus,
            selectedDefault: data.selectedDefault,
            selectedPublish: data.selectedPublish
        });
        setCurrentPage(1);
    }

    // Reset search and current page
    const resetSearch = () => {
        setSearchParams({planName: '', selectedStatus: '', selectedDefault: '', selectedPublish: ''});  // Reset search params
        setCurrentPage(1);  // Reset page to 1
    }

    const planStatus = (item) => {
        planStatusToggleReq(item.id);
    }

    const planDefault = (item) => {
        planDefaultToggleReq(item.id);
    }

    const pagination = (page) => {
        setCurrentPage(page);  // Set the current page in state
    }

    const refreshData = () => {
        let query = `?page=${currentPage}`;
        if (searchParams.planName) {
            query += `&name=${encodeURIComponent(searchParams.planName)}`;
        }
        if (searchParams.selectedStatus) {
            query += `&is_active=${searchParams.selectedStatus}`;
        }
        if (searchParams.selectedDefault) {
            query += `&is_default=${searchParams.selectedDefault}`;
        }
        if (searchParams.selectedPublish) {
            query += `&is_publish=${searchParams.selectedPublish}`;
        }
        sendRequest(query);
    }

    useEffect(() => {
        if (planStatusToggleStatus === 'completed' && planStatusToggleError === null) {
            let query = `?page=${currentPage}`;
            if (searchParams.planName) {
                query += `&name=${encodeURIComponent(searchParams.planName)}`;
            }
            if (searchParams.selectedStatus) {
                query += `&is_active=${searchParams.selectedStatus}`;
            }
            if (searchParams.selectedDefault) {
                query += `&is_default=${searchParams.selectedDefault}`;
            }
            if (searchParams.selectedPublish) {
                query += `&is_publish=${searchParams.selectedPublish}`;
            }
            sendRequest(query);
        }
    }, [planStatusToggleStatus, planStatusToggleError, planStatusToggleData, currentPage, searchParams]);

    useEffect(() => {
        if (planDefaultToggleStatus === 'completed' && planDefaultToggleError === null) {
            let query = `?page=${currentPage}`;
            if (searchParams.planName) {
                query += `&name=${encodeURIComponent(searchParams.planName)}`;
            }
            if (searchParams.selectedStatus) {
                query += `&is_active=${searchParams.selectedStatus}`;
            }
            if (searchParams.selectedDefault) {
                query += `&is_default=${searchParams.selectedDefault}`;
            }
            if (searchParams.selectedPublish) {
                query += `&is_publish=${searchParams.selectedPublish}`;
            }
            sendRequest(query);
        } else if (planDefaultToggleError) {
            const parsedError = JSON.parse(planDefaultToggleError);
            toast.error(`${parsedError.message}`);
        }
    }, [planDefaultToggleStatus, planDefaultToggleError, planDefaultToggleData]);

    return (
        <>
            {!error ? (
                <div className="admin-panel-wrap admin-all-trips flex flex-wrap w-100 content-justify-center">
                    <div className="container">
                        <h1>Subscription Plans</h1>
                        <div className='flex content-justify-end'>
                            <div>
                                <button className='signup-btn submit-btn'
                                        onClick={() => navigate('/admin-32423QeCFer/subscription-plans/create')}>Create
                                    New Plan
                                </button>
                                {/*<button className='signup-btn submit-btn' style={{*/}
                                {/*    marginTop:'10px'*/}
                                {/*}}*/}
                                {/*        onClick={() => navigate('/admin-32423QeCFer/subscription-plans/choose-plan')}>Choose Plan*/}
                                {/*</button>*/}
                            </div>
                        </div>
                        <br/>
                        <AdminSubscriptionPlansSearch submitSearch={(data) => submitSearch(data)} resetSearch={resetSearch}/>
                        {status === "pending" || status === null ? (
                            <SpinnerModal/>
                        ) : (
                            <>
                                <h3>List</h3>
                                <div className="table-responsive">
                                    <AdminSubscriptionPlansList
                                        data={data?.data}
                                        actionToggleStatus={(item) => planStatus(item)}
                                        disableStatus={planStatusToggleStatus}
                                        actionToggleDefault={(item) => planDefault(item)}
                                        disablDefault={planStatusToggleStatus}
                                        pagination={(page) => pagination(page)}
                                        totalCount={data?.total}
                                        currentPage={currentPage}
                                        refreshData={refreshData}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>

            ) : (
                <div className="main-profile-detail flex flex-wrap column-direction w-100">
                    <AdminApiErrors>{error}</AdminApiErrors>
                    <p className="no-trip">
                        An error occurred while fetching data from Backend. Please try again
                        later.
                    </p>
                </div>
            )}
        </>
    );
};
