// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Target the autocomplete dropdown */
body.location-autocomplete-active .pac-container {
    @media (max-width:600px) {
        left: 32px !important;
    margin-top: 16px !important;
    width: calc(100% - 64px) !important;
    }
    @media (min-width: 768px) {
        margin-top: 14px !important; 
    }
    @media (min-width: 700px) {
        margin-top: 20px !important; 
    }
    @media (min-width: 601px) {
      width: 500px !important;
      margin-top: 13px !important; 
    }
}

/* Optional: Add a subtle shadow for better separation */
body.location-autocomplete-active .pac-container {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/create-trip/Location.css"],"names":[],"mappings":"AAAA,qCAAqC;AACrC;IACI;QACI,qBAAqB;IACzB,2BAA2B;IAC3B,mCAAmC;IACnC;IACA;QACI,2BAA2B;IAC/B;IACA;QACI,2BAA2B;IAC/B;IACA;MACE,uBAAuB;MACvB,2BAA2B;IAC7B;AACJ;;AAEA,wDAAwD;AACxD;IACI,mDAAmD;AACvD","sourcesContent":["/* Target the autocomplete dropdown */\nbody.location-autocomplete-active .pac-container {\n    @media (max-width:600px) {\n        left: 32px !important;\n    margin-top: 16px !important;\n    width: calc(100% - 64px) !important;\n    }\n    @media (min-width: 768px) {\n        margin-top: 14px !important; \n    }\n    @media (min-width: 700px) {\n        margin-top: 20px !important; \n    }\n    @media (min-width: 601px) {\n      width: 500px !important;\n      margin-top: 13px !important; \n    }\n}\n\n/* Optional: Add a subtle shadow for better separation */\nbody.location-autocomplete-active .pac-container {\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
